<script setup lang="ts">
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { ComponentPublicInstance, computed, onMounted, ref } from 'vue';

import QuestionMeta from '@/components/conversation/QuestionMeta.vue';
import { getDomainName } from '@/models/Domain';
import { MessageInterface } from '@/models/Message';
import { calcNumberOfLinesInElement } from '@/services/utils/calcNumberOfLinesInElement';
import { getCurrentUnixTimestamp } from '@/services/utils/date';
import { useThreadStore } from '@/stores/thread';
import { useUserStore } from '@/stores/user';

const thread = useThreadStore();
const userStore = useUserStore();

const contextOrganisationName = computed(() =>
  userStore.getContextOrganisationIdentifier(thread.contextOrganisationId)
);

interface Props {
  message: MessageInterface;
}
const props = defineProps<Props>();

const questionElement = ref<ComponentPublicInstance>();
const questionSize = ref<TypographyVariants>(TypographyVariants.H1);
const questionCreatedAt = ref<number>(props.message.created_at ?? getCurrentUnixTimestamp());

onMounted(() => {
  if (calcNumberOfLinesInElement(questionElement.value?.$el) > 4) questionSize.value = TypographyVariants.H4;
  questionElement.value?.$el.scrollIntoView();
});
</script>
<template>
  <QuestionMeta
    :key="thread.uuid || 'meta'"
    :domain="getDomainName(thread.domain)"
    :contextOrganisationName="contextOrganisationName"
    :createdAt="questionCreatedAt"
    :loading="!thread.hasAssistantResponded"
  />
  <Typography ref="questionElement" class="question" :variant="questionSize" noMargin>{{
    message.content?.body
  }}</Typography>
</template>

<style scoped lang="scss">
.question {
  white-space: pre-wrap;
  padding-bottom: 1.5rem;
  word-break: break-word;
}
</style>
