<script setup lang="ts">
import Action from '@/components/conversation/response/message/Action.vue';
import { MessageContentInterface } from '@/models/Message';
import { useLocaleTranslator } from '@/translations';

interface Props {
  content: MessageContentInterface;
}

defineProps<Props>();
const { translate } = useLocaleTranslator();
</script>

<template>
  <div class="content">
    <div class="body" v-html="content.body" />
    <div v-if="content.conclusion" class="conclusion">
      <h4>{{ translate('conversation.conclusion.title') }}</h4>
      <div v-html="content.conclusion" />
    </div>
  </div>
  <div v-if="content.actions && content.actions?.length > 0" class="actions">
    <Action v-for="(action, index) in content.actions" :key="index" :action="action" />
  </div>
</template>

<style scoped lang="scss">
.actions {
  margin-top: 1rem;
  display: inline-flex;
}

.body,
.conclusion {
  @import '@/assets/scss/shared-typography';
}
</style>
