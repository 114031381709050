/**
 * Converts a string to a boolean.
 * @param str - The string to convert.
 * @returns boolean - The boolean value of the string.
 */
export const stringToBool = (str: string | null | undefined): boolean => {
  if (typeof str !== 'string') {
    return false;
  }
  const trueValues = ['true', '1', 'yes', 'y'];
  return trueValues.includes(str.toLowerCase());
};
