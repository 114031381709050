import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';

import mockApi from '@/services/api/knowledgeBase/mockApi';
import AuthService from '@/services/auth/authService';
import { isTokenExpired } from '@/services/auth/jwt/validator';
import { stringToBool } from '@/services/utils/typeCasters';

interface Config extends InternalAxiosRequestConfig {
  headers: AxiosRequestHeaders & {
    'X-Authorization-Refresh-Token'?: string;
    'X-Authorization-Service'?: string;
  };
  url: string;
  refreshTokenRequest?: boolean;
}

const api = axios.create({
  baseURL: import.meta.env.VITE_KB_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const extendedConfig = config as Config;

    let token = AuthService.getAccessToken();

    if (!extendedConfig.refreshTokenRequest && token && isTokenExpired(token)) {
      token = await AuthService.refreshAccessToken();
    }

    if (token) {
      Object.assign(extendedConfig.headers, {
        ...extendedConfig.headers,
        'X-Authorization-Token': token,
        'X-Authorization-Service': import.meta.env.VITE_KB_API_AUTH_SERVICE
      });
    }

    return extendedConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await AuthService.refreshAccessToken();
        originalRequest.headers = {
          ...originalRequest.headers,
          'X-Authorization-Token': newAccessToken,
          'X-Authorization-Service': import.meta.env.VITE_KB_API_AUTH_SERVICE
        };

        return api(originalRequest);
      } catch (refreshError) {
        return Promise.reject(error);
      }
    }

    console.error('Error (Knowledge Base API):', error.response);

    return Promise.reject(error);
  }
);
export default stringToBool(import.meta.env.VITE_USE_MOCK_API) ? mockApi : api;
