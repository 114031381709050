<script setup lang="ts">
import Select from '@schulinck/components/forms/select/Select.vue';
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import { TooltipPosition } from '@schulinck/components/ui/tooltip/Tooltip.types';
import Tooltip from '@schulinck/components/ui/tooltip/Tooltip.vue';
import { computed, ref } from 'vue';

import { DomainDataInterface } from '@/models/Domain';
import { DomainSelectedEventData, EventType } from '@/models/Event';
import { Event } from '@/services/tracking/event';
import { tracker } from '@/services/tracking/tracker';
import { useUserStore } from '@/stores/user';
import { useLocaleTranslator } from '@/translations';

interface Props {
  errorDomainSelect: boolean;
}

defineProps<Props>();

const userStore = useUserStore();
const { translate } = useLocaleTranslator();
const domainSelectorReference = ref<HTMLElement>();
const policySelectorReference = ref<HTMLElement>();

const availableDomains = computed(() => userStore.userDomains);
const currentDomainCode = computed(() => (userStore.lastChosenDomain ? userStore.lastChosenDomain.productCode : null));
const availableDomainsOptions = computed(() =>
  availableDomains.value.map((domain: DomainDataInterface) => ({ label: domain.name, value: domain.productCode }))
);

const currentContextOrganisationId = computed(() => userStore.lastChosenContextOrganisationId);

const availableOrganisatonOptions = [
  ...userStore.availableContextOrganisations,
  {
    label: translate('prompt.select.option.no_policy'),
    value: '-'
  }
];

const organisationPolicyTooltipName = computed(() =>
  userStore.getContextOrganisationIdentifier(currentContextOrganisationId.value)
);

const domainTooltipTitle = computed(() => translate('prompt.select.tooltip.product'));
const policyTooltipTitle = computed(() =>
  organisationPolicyTooltipName.value
    ? translate('prompt.select.tooltip.policy', { organisation: organisationPolicyTooltipName.value })
    : translate('prompt.select.tooltip.no_policy')
);

const domainSelectedEvent: Event<DomainSelectedEventData> = new Event().setType(EventType.DomainSelected);

const handleDomainChange = (value: string) => {
  userStore.setLastChosenDomainCode(value);
  domainSelectedEvent.setData({ domain_code: value });
  tracker.push(domainSelectedEvent);
};

const handlePolicyChange = (value: string | null) => {
  userStore.setLastChosenContextOrganisationId(value);
};
</script>

<template>
  <Cluster :gap="0.5">
    <Tooltip :title="domainTooltipTitle" :position="TooltipPosition.Bottom">
      <Select
        ref="domainSelectorReference"
        :class="['select domain', errorDomainSelect && 'error']"
        :value="currentDomainCode"
        :options="availableDomainsOptions"
        :onUpdate="(value) => handleDomainChange(value as string)"
        :disabled="availableDomains.length <= 1"
        :placeholder="translate('prompt.select.product')"
      />
    </Tooltip>
    <Tooltip :title="policyTooltipTitle" :position="TooltipPosition.Bottom">
      <Select
        v-if="!userStore.excludedFromContextOrganisation()"
        ref="policySelectorReference"
        :class="['select policy']"
        :value="currentContextOrganisationId"
        :options="availableOrganisatonOptions"
        :onUpdate="(value) => handlePolicyChange(value as string)"
        :disabled="availableOrganisatonOptions.length <= 1"
        :placeholder="translate('prompt.select.policy')"
      />
    </Tooltip>
  </Cluster>
</template>

<style scoped lang="scss">
.select {
  border-radius: 2rem;

  &.error {
    border: 1px solid $wk-red;
    background: rgba($wk-red-tint3, 0.2);
  }

  &:hover:not(.error, :disabled) {
    background-color: $wk-blue-tint5;
  }

  ::v-deep(.wk-select-active) {
    &:focus-within:not(.error, :disabled) {
      background-color: $wk-blue-tint5;
    }

    .wk-select-icon {
      transform: rotate(-180deg);
    }
  }

  &.disabled {
    ::v-deep(.wk-select) {
      cursor: default;

      .wk-select-icon {
        display: none;
      }
    }
  }

  ::v-deep(.wk-select) {
    border: transparent;
    background-color: transparent;
    padding: 0.4rem 0.6rem;
    height: auto;
    font-size: 0.875rem;
    border-radius: 2rem;

    &::before {
      font-family: $icon-font;
      content: '';
      margin-right: 0.25rem;
    }

    .wk-select-title {
      font-size: 0.875rem;
    }
    .wk-select-icon {
      margin-left: 0.25rem;
    }
  }

  &.domain {
    ::v-deep(.wk-select) {
      &::before {
        content: icon(book);
      }
    }
  }

  &.policy {
    ::v-deep(.wk-select) {
      &::before {
        font-family: $icon-custom-font;
        content: icon(municipality, $icons-custom);
      }
    }
  }
}
</style>
