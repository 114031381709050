<script async setup lang="ts">
import Fishbowl from '@schulinck/components/ui/fishbowl/Fishbowl.vue';
import Menu from '@schulinck/components/ui/stacked-menu/models/Menu';
import MenuItem from '@schulinck/components/ui/stacked-menu/models/MenuItem';
import StackedMenu from '@schulinck/components/ui/stacked-menu/views/StackedMenu.vue';
import StackedMenuPartHeader from '@schulinck/components/ui/stacked-menu/views/StackedMenuPartHeader.vue';
import useDetectOutsideClick from '@schulinck/hooks/ui/useDetectOutsideClick';
import { computed, Ref, ref, shallowRef, watch } from 'vue';

import { EventState, EventType, ProfileMenuEventData, ProfileMenuItemEventData } from '@/models/Event';
import Organisation from '@/models/Organisation';
import ProfileMenu, { ProfileMenuDataInterface } from '@/models/ProfileMenu';
import { UserDetailsDataInterface } from '@/models/UserDetails';
import AuthService from '@/services/auth/authService';
import { Event } from '@/services/tracking/event';
import { tracker } from '@/services/tracking/tracker';
import { useUserStore } from '@/stores/user';

import { menuItemsConfig } from './config/ProfileMenu.config';
import { createProfileMenuContents } from './utils/CreateProfileMenuContents';

const userStore = useUserStore();

const profileMenu = computed(() => {
  const userDetails: UserDetailsDataInterface | null = userStore.userDetails;
  const organisations = userStore.userOrganisations;
  const products = userStore.userProducts;

  const menuData = createProfileMenuContents(organisations, products);

  const profileMenuData: ProfileMenuDataInterface = {
    username: userDetails ? `${userDetails.firstName} ${userDetails.lastName}`.trim() : '',
    userInitials: userDetails?.initials,
    organisationName: userDetails?.lastLoggedInKnowledgeBaseOrganisationId || 'n/a',
    _embedded: menuData
  };

  return new ProfileMenu(profileMenuData);
});

const selectedOrganisation: Ref<Organisation | null> = computed(() => userStore.selectedOrganisation);
const profileMenuVisible = shallowRef(false);
const componentRef = ref();

useDetectOutsideClick(componentRef, (): void => {
  profileMenuVisible.value = false;
});

const profileMenuEvent: Event<ProfileMenuEventData> = new Event().setType(EventType.ProfileMenu);
const profileMenuItemEvent: Event<ProfileMenuItemEventData> = new Event().setType(EventType.ProfileMenuItem);

const setupMenu = (menu: Menu | MenuItem): void => {
  for (const menuItem of menu.menuItems.items) {
    menuItem.title = menuItem.title || menuItemsConfig[menuItem.name]?.title || '';
    menuItem.icon = menuItemsConfig[menuItem.name]?.icon;
    setupMenu(menuItem);
  }
};

const handleMenuItemClicked = async (menuItem: MenuItem): Promise<void> => {
  profileMenuItemEvent.setData({
    menu_item: menuItem.title
  });

  tracker.push(profileMenuItemEvent);

  if (menuItem.name === 'logout') {
    await AuthService.logout(userStore.userDetails.id);
  }
};

watch(profileMenuVisible, async (newState) => {
  profileMenuEvent.setData({
    state: newState ? EventState.Open : EventState.Closed
  });

  tracker.push(profileMenuEvent);
});

if (profileMenu.value) setupMenu(profileMenu.value.menu);
</script>
<template>
  <div ref="componentRef">
    <Fishbowl
      :onClick="() => (profileMenuVisible = !profileMenuVisible)"
      :username="profileMenu.username"
      :organisationName="selectedOrganisation?.name || ''"
      :userInitials="profileMenu.userInitials"
      :active="profileMenuVisible"
    />
    <transition name="wk-stacked-menu-slide-down">
      <StackedMenu v-if="profileMenuVisible" :menu="profileMenu.menu" :onMenuItemClicked="handleMenuItemClicked">
        <template #menuItem-myOrganisation="{ menuItem }">
          <StackedMenuPartHeader
            :title="menuItem.title"
            :iconLeft="menuItem.icon"
            :iconLeftSize="6"
          ></StackedMenuPartHeader>
        </template>
      </StackedMenu>
    </transition>
  </div>
</template>

<style scoped lang="scss">
.wk-stacked-menu {
  position: absolute;
  z-index: map-get($zIndexes, 'profileMenu');
  width: 100%;
  left: 0;

  @include mq(xs) {
    width: 290px;
    right: 2rem;
    left: auto;
  }
}
.wk-stacked-menu-slide-down-enter-active,
.wk-stacked-menu-slide-down-leave-active {
  transition: all 0.15s ease-in-out;
  overflow: hidden;
}

.wk-stacked-menu-slide-down-leave-active {
  max-height: 400px;
  opacity: 1;
}

.wk-stacked-menu-slide-down-leave-to {
  max-height: 0;
  opacity: 0;
}

.wk-stacked-menu-slide-down-enter-active {
  max-height: 0;
  opacity: 0;
}

.wk-stacked-menu-slide-down-enter-to {
  max-height: 400px;
  opacity: 1;
}

::v-deep(.wk-fishbowl) {
  &:hover,
  &.active {
    background-color: $wk-blue-tint6;
    border-radius: 0.25rem;
  }
}
</style>
