<script setup lang="ts">
import { BackdropTypes } from '@schulinck/components/ui/backdrop/Backdrop.types';
import Backdrop from '@schulinck/components/ui/backdrop/Backdrop.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import IconButton from '@schulinck/components/ui/icon-button/IconButton.vue';
import { defineProps, ref } from 'vue';

interface Props {
  show: boolean;
  onClose: () => void;
}

const { show = false } = defineProps<Props>();

const showContent = ref(false);
const hideContent = (event: MouseEvent) => {
  showContent.value = false;
  event.stopPropagation();
};
</script>
<template>
  <transition name="fade-in" @after-enter="() => (showContent = true)">
    <Backdrop v-if="show" :type="BackdropTypes.Dark" :onClick="hideContent">
      <transition name="slide-in" @after-leave="onClose()">
        <div v-if="show && showContent" :class="['pane slide']" @click.stop>
          <div class="header">
            <IconButton :class="['close-button']" :onClick="hideContent">
              <Icon :name="IconNames.Close" />
            </IconButton>
          </div>
          <div class="content">
            <slot />
          </div>
        </div>
      </transition>
    </Backdrop>
  </transition>
</template>

<style scoped lang="scss">
.pane {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: white;
  top: 0;
  display: flex;
  flex-direction: column;
  right: 0;

  @include mq(sm) {
    width: min(768px, 50%);
  }

  .header {
    position: absolute;
    z-index: map-get($zIndexes, 'hide-sources');
    right: 1.5rem;
    top: 0.5rem;

    .close-button {
      border-radius: 50%;
      background-color: $wk-white;

      &:hover {
        background-color: $wk-blue-tint6;
      }
    }
  }

  .content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.slide-in-enter-active,
.slide-in-leave-active {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  transform: translateX(0);
}

.slide-in-enter-from,
.slide-in-leave-to {
  opacity: 0;
  &.slide {
    transform: translateX(100%);
  }
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: all 0.3s ease-in-out;
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}
</style>
