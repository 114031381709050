<script setup lang="ts">
import TextField from '@schulinck/components/forms/text-field/TextField.vue';
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import { ButtonTypes, ButtonVariants } from '@schulinck/components/ui/button/Button.types';
import Button from '@schulinck/components/ui/button/Button.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import CloseIconButton from '@schulinck/components/ui/icon-button/CloseIconButton.vue';
import Loader from '@schulinck/components/ui/loader/Loader.vue';
import Tab from '@schulinck/components/ui/tabs/Tab.vue';
import Tabs from '@schulinck/components/ui/tabs/Tabs.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { computed, ref, watch } from 'vue';

import { ContentType, Source } from '@/models/Message';
import { NotificationType } from '@/models/Notification';
import saApi from '@/services/api/assistant/api';
import { useNotificationStore } from '@/stores/notification';
import { useThreadStore } from '@/stores/thread';
import { useUserStore } from '@/stores/user';
import { useLocaleTranslator } from '@/translations';

import Option from './../elements/Option.vue';
import GasSources from './GasSources.vue';

interface Props {
  show: boolean;
  reference: HTMLElement | undefined;
  onHide: () => void;
  traceId: string;
  sources: Source[] | undefined;
  onHasData: (hasData: boolean) => void;
}

interface FormData {
  municipality_specific: boolean | null;
  add_to_gas: boolean;
  ground_truth_sources: string[];
  external_gas_ids: string[];
  ground_truth_answer: string;
  generated_answer_correct: boolean;
  question_classifier: string | null;
  notes: string;
}

const props = defineProps<Props>();

/* REVIEW: are these right cases to check classification?*/
const question_classifiers = [
  { value: 'definition', label: 'Definitie / Feitelijk' },
  { value: 'scenario', label: 'Scenario / Casus' },
  { value: 'question', label: 'Overige vraag' }
];

const userStore = useUserStore();

const { translate } = useLocaleTranslator();

const editorialFeedbackForm = ref<HTMLFormElement>();
const feedbackFormContainer = ref<HTMLFormElement>();
const transitionDuration = 500;
const transitionDurationString = `${transitionDuration}ms`;
const activeTab = ref(0);
const showLoading = ref(false);

const showGasFeedbackForm = computed(() => props.show && props.reference);

const defaultFormData: FormData = {
  municipality_specific: null,
  add_to_gas: false,
  ground_truth_sources: [],
  external_gas_ids: [],
  ground_truth_answer: '',
  generated_answer_correct: false,
  question_classifier: null,
  notes: ''
};

const formData = ref<FormData>(structuredClone(defaultFormData));

const formHasData = computed(() => {
  // For more complex objects (Dates, Functions, Maps, Sets).
  return JSON.stringify(formData.value) !== JSON.stringify(defaultFormData);
});

const errorMessage = ref<string | null>(null);

const showNotification = () => {
  useNotificationStore().showNotification(
    NotificationType.Success,
    translate('editorialFeedback.notificationTitle'),
    translate('editorialFeedback.notificationContent'),
    2500
  );
};

const getFormData = async () => {
  try {
    const response = await saApi.post('/chat/get-editorial-feedback', { input: { trace_id: props.traceId } });
    const output = response.data?.output?.data;
    if (!output) {
      return null;
    }
    const data: FormData = {
      municipality_specific: output?.municipality_specific ?? null,
      add_to_gas: output?.add_to_gas ?? false,
      ground_truth_sources: output?.ground_truth_sources ?? [],
      external_gas_ids: output?.external_gas_ids ?? [''],
      ground_truth_answer: output?.ground_truth_answer ?? '',
      generated_answer_correct: output?.generated_answer_correct ?? false,
      question_classifier: output?.question_classifier ?? null,
      notes: output?.notes ?? ''
    };

    return data;
  } catch (error: unknown) {
    errorMessage.value = error instanceof Error ? error.message : 'Formulier kan niet worden opgehaald';
  }
};

const filteredSources = computed(() => {
  return props.sources?.filter((source) => source.content_type !== ContentType.JiraThread);
});

const postFormData = async () => {
  const userDetails = userStore.userDetails;

  try {
    const response = await saApi.post('/chat/editorial-feedback', {
      input: {
        editor: userDetails ? `${userDetails.firstName} ${userDetails.lastName}`.trim() : '',
        trace_id: props.traceId,
        thread_uuid: useThreadStore().uuid,
        data: formData.value
      }
    });
    return response.data; // Return the response data
  } catch (error: unknown) {
    errorMessage.value = error instanceof Error ? error.message : 'formulier kan niet worden verzonden';
    throw error; // Optionally rethrow the error if you want the caller to handle it
  }
};

const onSubmitForm = () => {
  showLoading.value = true;
  postFormData()
    .then((data) => {
      showLoading.value = false;
      if (data !== null && data !== undefined) {
        showNotification();
        props.onHide();
      }
    })
    .catch(() => {
      showLoading.value = false;
    });
};

const selectTab = (index: number) => {
  activeTab.value = index;
};

const loadData = async () => {
  showLoading.value = true;
  const data = await getFormData();
  if (data) {
    formData.value = {
      ...formData.value,
      ...data
    };
  }
  showLoading.value = false;
};

watch(formData.value, () => props.onHasData(formHasData.value));

watch(showGasFeedbackForm, () => {
  if (showGasFeedbackForm.value) {
    loadData();

    setTimeout(() => {
      feedbackFormContainer.value?.scrollIntoView({ block: 'center' });
    }, transitionDuration);
  }
});
</script>

<template>
  <div
    ref="feedbackFormContainer"
    :class="['editorial-feedback-form-container', showGasFeedbackForm && 'open']"
    :offset="20"
    :onClose="onHide"
    :reference="reference"
  >
    <Loader :show="showLoading" />
    <form ref="editorialFeedbackForm" @submit.prevent="onSubmitForm()">
      <Stack class="editorial-feedback-form">
        <Cluster class="feedback-form-header">
          <Typography :variant="TypographyVariants.Paragraph" :size="1" noMargin>{{
            translate('editorialFeedback.title')
          }}</Typography>
          <CloseIconButton @click.prevent="onHide" />
        </Cluster>

        <Stack :gap="0.5">
          <Stack>
            <label class="title">
              {{ translate('editorialFeedback.municipality_specific_checkbox_label') }}
            </label>
            <Cluster :gap="0.5" wrap>
              <Option
                v-for="(option, index) in [
                  { value: true, label: translate('editorialFeedback.yes') },
                  { value: false, label: translate('editorialFeedback.no') }
                ]"
                :key="index"
                :option="option.label"
                name="municipality_specific"
                :selected="formData.municipality_specific === option.value"
                @click="formData.municipality_specific = option.value"
              />
            </Cluster>
          </Stack>
          <Stack>
            <label class="title">{{ translate('editorialFeedback.municipality_specific_title') }}</label>
            <Cluster :gap="0.5" wrap>
              <Option
                v-for="classifier in question_classifiers"
                :key="classifier.value"
                name="question_classifier"
                :option="classifier.label"
                :selected="formData.question_classifier === classifier.value"
                @click="formData.question_classifier = classifier.value"
              />
            </Cluster>
          </Stack>
          <label>
            <input v-model="formData.add_to_gas" type="checkbox" />
            {{ translate('editorialFeedback.add_to_gas_checkbox_label') }}
          </label>
        </Stack>
        <div v-if="formData.add_to_gas" class="gas-sources-wrapper">
          <Tabs class="gas-sources-tabs">
            <Tab
              :key="0"
              :active="activeTab === 0"
              :onClick="
                (event) => {
                  event.preventDefault();
                  selectTab(0);
                }
              "
            >
              <Typography :variant="TypographyVariants.Paragraph" noMargin>
                {{ translate('editorialFeedback.firstTabTitle') }}
              </Typography>
            </Tab>
            <Tab
              :key="1"
              :active="activeTab === 1"
              :onClick="
                (event) => {
                  event.preventDefault();
                  selectTab(1);
                }
              "
            >
              <Typography :variant="TypographyVariants.Paragraph" noMargin>
                {{ translate('editorialFeedback.secondTabTitle') }}
              </Typography>
            </Tab>
          </Tabs>
          <div v-if="activeTab === 0">
            <GasSources
              v-model:ground_truth_sources="formData.ground_truth_sources"
              v-model:external_gas_ids="formData.external_gas_ids"
              :sources="filteredSources"
            />
          </div>
          <div v-if="activeTab === 1">
            <Stack :gap="0.5">
              <Stack>
                <label>
                  <input v-model="formData.generated_answer_correct" type="checkbox" />
                  {{ translate('editorialFeedback.generated_answer_correct_checkbox_label') }}
                </label>
              </Stack>

              <Stack v-if="!formData.generated_answer_correct">
                <label class="title">{{ translate('editorialFeedback.groundTruthTitle') }}</label>
                <div class="feedback-ground-truth">
                  <TextField
                    multiline
                    :value="formData.ground_truth_answer"
                    :name="'text'"
                    :resize="'vertical'"
                    :required="false"
                    :placeholder="translate('editorialFeedback.groundTruthPlaceholder')"
                    @input="
                      (e) => {
                        formData.ground_truth_answer = e.target.value;
                        console.log('e.target.value', e.target.value, formData.ground_truth_answer);
                      }
                    "
                  />
                </div>
              </Stack>
            </Stack>
          </div>
        </div>
        <Stack>
          <label class="title">{{ translate('editorialFeedback.notes') }}</label>
          <div>
            <TextField
              multiline
              :value="formData.notes"
              :name="'text'"
              :resize="'vertical'"
              :required="false"
              :placeholder="translate('editorialFeedback.notesPlaceholder')"
              @input="
                (e) => {
                  formData.notes = e.target.value;
                }
              "
            />
          </div>
        </Stack>

        <Cluster :alignItems="Position.Center" :justifyContent="Position.Start">
          <Button :type="ButtonTypes.Submit" :disabled="!formHasData">
            <Icon :name="IconNames.Send" />
            {{ translate('feedback.submit') }}
          </Button>
          <Button class="cancel" :variant="ButtonVariants.Text" :onClick="onHide">
            {{ translate('feedback.cancel') }}
          </Button>
        </Cluster>
      </Stack>
    </form>
  </div>
</template>

<style scoped lang="scss">
.gas-sources-wrapper {
  padding: 1rem;
  border: 1px solid $wk-gray-tint4;
}

.editorial-feedback-form-container {
  padding: 0;
  margin-bottom: 1rem;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows v-bind(transitionDurationString);
  position: relative;
  font-size: 14px;

  &::before {
    content: '';
    position: absolute;
    top: -0.25rem;
    left: 275px;
    width: 0.5rem;
    height: 0.5rem;
    background-color: $wk-white;
    border-top: 1px solid $wk-gray-tint4;
    border-left: 1px solid $wk-gray-tint4;
    transform: rotate(45deg);
    z-index: map-get($zIndexes, 'tooltip-pointer-feedback-form');
    opacity: 0;
    transition: 0.1s opacity ease-in-out;
  }

  &.open {
    grid-template-rows: 1fr;

    &::before {
      opacity: 1;
    }
  }

  & > form {
    overflow: hidden;
  }
}

.gas-sources-tabs {
  margin-bottom: 1rem;
}

.editorial-feedback-form {
  border-radius: 0.5rem;
  border: 1px solid $wk-gray-tint4;
  background: $wk-white;
  padding: 1rem;
  .feedback-form-header {
    height: auto;
    position: relative;

    ::v-deep(.wk-close-icon-button) {
      top: -1rem;
      right: -1rem;
    }
  }

  .option {
    cursor: pointer;
    border-radius: 80px;
    border: 1px solid $wk-gray-tint4;
    background: $wk-white;
    font-size: 0.875rem;
    padding: 6px 16px 6px 12px;
    display: flex;
    gap: 6px;

    &:hover,
    &.selected {
      background-color: $wk-blue-tint5;
    }

    input {
      cursor: pointer;
    }
  }

  .wk-typography-h4,
  .counter {
    font-size: 0.875rem;
  }

  .feedback-explanation {
    ::v-deep(.wk-text-field-multiline) {
      height: 75px;
      resize: vertical;
    }
  }

  button[type='submit'] {
    @include gradient-background;
    border-radius: 2rem;
    padding: 0 1rem;
    font-size: 1rem;

    ::v-deep(.wk-icon) {
      margin-left: 0;
      margin-right: 0.5rem;
    }

    &:disabled {
      background: $wk-gray-tint4;
      cursor: not-allowed;
    }
  }

  .cancel {
    padding-left: 0;
    padding-right: 0;
    font-size: 1rem;
    color: $wk-blue-shade1;
  }

  label.title {
    font-weight: 500;
  }

  .radio-group-container {
    padding-left: 1rem;
    label {
      display: block;
    }
  }
}
</style>
