<script setup lang="ts">
import TextField from '@schulinck/components/forms/text-field/TextField.vue';
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import { ButtonTypes, ButtonVariants } from '@schulinck/components/ui/button/Button.types';
import Button from '@schulinck/components/ui/button/Button.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import CloseIconButton from '@schulinck/components/ui/icon-button/CloseIconButton.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { computed, ref, watch } from 'vue';

import { EventType, FeedbackFormEventData } from '@/models/Event';
import { FeedbackFormValues } from '@/models/Feedback';
import { NotificationType } from '@/models/Notification';
import { Event } from '@/services/tracking/event';
import { tracker } from '@/services/tracking/tracker';
import { useNotificationStore } from '@/stores/notification';
import { useLocaleTranslator } from '@/translations';

import Option from './elements/Option.vue';

interface Props {
  show: boolean;
  reference: HTMLElement | undefined;
  onHide: () => void;
  onSubmit: (values: FeedbackFormValues) => void;
  traceId: string;
}

const props = defineProps<Props>();

const { translate } = useLocaleTranslator();

const feedbackOptions = ref<string[]>([
  'feedback.incorrectSources',
  'feedback.deprecatedSources',
  'feedback.incorrectResponse',
  'feedback.other'
]);

const feedbackText = ref('');
const selectedOption = ref('');
const feedbackForm = ref<HTMLFormElement>();
const feedbackFormContainer = ref<HTMLFormElement>();
const maxFeedbackLength = 2000;
const transitionDuration = 500;
const transitionDurationString = `${transitionDuration}ms`;

const feedbackLengthExceeded = computed(() => feedbackText.value.length > maxFeedbackLength);
const preventSubmit = computed(() => feedbackLengthExceeded.value);
const showFeedbackForm = computed(() => props.show && props.reference);
const feedbackFormEvent: Event<FeedbackFormEventData> = new Event().setType(EventType.AnswerFeedbackForm);

const handleInput = (event: InputEvent) => {
  const target = event.target as HTMLInputElement;
  feedbackText.value = target.value;
};

const onSubmitForm = () => {
  const formData = new FormData(feedbackForm.value);
  const formValues: FeedbackFormValues = Object.fromEntries(formData);

  sendFormFeedbackEvent(formValues);
  props.onSubmit(formValues);
  clearForm();
};

const clearForm = () => (feedbackText.value = selectedOption.value = '');

const sendFormFeedbackEvent = (values: FeedbackFormValues) => {
  showNotification();
  feedbackFormEvent.setData({
    trace_id: props.traceId,
    reason: translate(`${values.option}`)
  });
  tracker.push(feedbackFormEvent);
};

const showNotification = () => {
  useNotificationStore().showNotification(
    NotificationType.Success,
    translate('feedback.notificationTitle'),
    translate('feedback.notificationContent'),
    2500
  );
};

watch([showFeedbackForm, selectedOption], ([showForm, selectedOpt]) => {
  if (showForm || selectedOpt) {
    setTimeout(() => {
      feedbackFormContainer.value?.scrollIntoView({ block: 'center' });
    }, transitionDuration);
  }
});
</script>

<template>
  <div
    ref="feedbackFormContainer"
    :class="['response-feedback-form-container', showFeedbackForm && 'open']"
    :offset="20"
    :onClose="onHide"
  >
    <form ref="feedbackForm" @submit.prevent="onSubmitForm()">
      <Stack class="feedback-form">
        <Cluster class="feedback-form-header">
          <Typography :variant="TypographyVariants.Paragraph" :size="1" noMargin>{{
            translate('feedback.title')
          }}</Typography>
          <CloseIconButton @click.prevent="onHide" />
        </Cluster>

        <Cluster :gap="0.5" wrap>
          <Option
            v-for="option in feedbackOptions"
            :key="option"
            :option="option"
            :selected="selectedOption === option"
            @click="selectedOption = option"
          />
        </Cluster>
        <Stack v-if="selectedOption" :gap="0.25">
          <Typography :variant="TypographyVariants.H4" noMargin>{{
            translate('feedback.explanationTitle')
          }}</Typography>
          <TextField
            class="feedback-explanation"
            multiline
            :value="feedbackText"
            :name="'text'"
            :resize="'vertical'"
            :required="false"
            :placeholder="translate('feedback.explanationPlaceholder')"
            @input="handleInput"
          />
        </Stack>
        <Cluster :alignItems="Position.Center" :justifyContent="Position.Start">
          <Button :type="ButtonTypes.Submit" :disabled="preventSubmit">
            <Icon :name="IconNames.Send" />
            {{ translate('feedback.submit') }}
          </Button>
          <Button class="cancel" :variant="ButtonVariants.Text" :onClick="onHide">
            {{ translate('feedback.cancel') }}
          </Button>
        </Cluster>
      </Stack>
    </form>
  </div>
</template>

<style scoped lang="scss">
.response-feedback-form-container {
  padding: 0;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows v-bind(transitionDurationString);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -0.25rem;
    left: 225px;
    width: 0.5rem;
    height: 0.5rem;
    background-color: $wk-white;
    border-top: 1px solid $wk-gray-tint4;
    border-left: 1px solid $wk-gray-tint4;
    transform: rotate(45deg);
    z-index: map-get($zIndexes, 'tooltip-pointer-feedback-form');
    opacity: 0;
    transition: 0.1s opacity ease-in-out;
  }

  &.open {
    grid-template-rows: 1fr;
    margin-bottom: 1rem;

    &::before {
      opacity: 1;
    }
  }

  & > form {
    overflow: hidden;
  }
}

.feedback-form {
  border-radius: 0.5rem;
  border: 1px solid $wk-gray-tint4;
  background: $wk-white;
  padding: 1rem;
  .feedback-form-header {
    height: auto;
    position: relative;

    ::v-deep(.wk-close-icon-button) {
      top: -1rem;
      right: -1rem;
    }
  }

  .wk-typography-h4,
  .counter {
    font-size: 0.875rem;
  }

  .feedback-explanation {
    ::v-deep(.wk-text-field-multiline) {
      height: 75px;
      resize: vertical;
      border-color: $wk-gray-tint1;
    }
  }

  button[type='submit'] {
    @include gradient-background;
    border-radius: 2rem;
    padding: 0 1rem;
    font-size: 1rem;

    ::v-deep(.wk-icon) {
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }

  .cancel {
    padding-left: 0;
    padding-right: 0;
    font-size: 1rem;
    color: $wk-blue-shade1;
  }
}
</style>
