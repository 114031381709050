import { App } from 'vue';

import { initGtag } from '@/config/tracking/gtag';
import { initMouseFlow } from '@/config/tracking/mouseFlow';
import { initSentry } from '@/config/tracking/sentry';
import { ConsentValues, getConsentValues } from '@/services/tracking/cookieConsent';

const initializeTrackingScripts = (app: App, consentValues: ConsentValues) => {
  if (consentValues.Necessary) {
    // Initialize necessary tracking scripts here
  }
  if (consentValues.Functional) {
    // Initialize functional tracking scripts here
  }
  if (consentValues.Performance) {
    initGtag(app);
    initSentry(app);
    initMouseFlow();
  }
  if (consentValues.Interest) {
    // Initialize interest tracking scripts here
  }
};

export const initConfig = async (app: App) => {
  try {
    initializeTrackingScripts(app, await getConsentValues());
  } catch (error) {
    console.error('Failed to initialize config:', error);
  }
};
