export default {
  'response.label': 'Wat vind je van dit antwoord?',
  'source.label': 'Wat vind je van deze bron?',
  title: 'Redactionele feedback',

  firstTabTitle: 'Selecteer Bronnen',
  secondTabTitle: 'Gewenst antwoord toevoegen',

  municipality_specific_checkbox_label: 'Vraag is gemeentelijk beleid gerelateerd?',
  municipality_specific_title: 'Hoe zou je deze vraag classificeren?',

  add_to_gas_checkbox_label: 'Vraag opnemen in Golden Answer Set (GAS)',

  generated_answer_correct_checkbox_label: 'Het gegenereerde antwoord is wat ik zou verwachten.',
  groundTruthTitle: 'Ground truth answer',
  groundTruthPlaceholder: 'Vul hier het verwachte antwoord in...',

  submit: 'Versturen',
  cancel: 'Formulier sluiten',
  yes: 'Ja',
  no: 'Nee',

  gasIdPlaceholder: 'GAS-id invullen...',

  gasSourcesTitle: 'Selecteer welke bronnen opgenomen moeten worden in de Golden Answer Set.',
  externalGasFieldsTitle: 'Externe bronnen toevoegen',

  notificationTitle: 'Bedankt voor je feedback!',
  notificationContent: 'Je feedback is ontvangen en we gaan er mee aan de slag',

  notes: 'Opmerkingen',
  notesPlaceholder: 'Voeg hier eventuele opmerkingen toe...'
};
