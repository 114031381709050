<script setup lang="ts">
import Clickable from '@schulinck/components/ui/clickable/Clickable.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';

interface Props {
  focussed?: boolean;
  hasData?: boolean;
  onClick: () => void;
}

const { onClick } = defineProps<Props>();

const gasFeedbackClicked = () => {
  onClick();
};
</script>

<template>
  <div :class="['gasfeedback', focussed && 'active']">
    <Clickable @click.stop.prevent="gasFeedbackClicked">
      <Icon :name="IconNames.ChatBox" :filled="hasData" />
    </Clickable>
  </div>
</template>

<style scoped lang="scss">
.gasfeedback {
  border-left: 1px solid $wk-gray-tint5;
  margin-left: 0.25rem;
  padding-left: 0.25rem;

  .wk-clickable {
    position: relative;
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    border-radius: 50%;

    &:hover {
      background-color: $wk-blue-tint6;
    }

    i {
      color: $wk-blue-shade1;
      margin: 0;
    }
  }

  &.active .wk-clickable {
    background-color: $wk-blue-tint6;
  }
}
</style>
