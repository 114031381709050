import { createPinia } from 'pinia';
import { createApp } from 'vue';

import { initConfig } from '@/config/config';
import { useSetPageTitle } from '@/hooks/useSetPageTitle';
import PermissionPlugin from '@/plugins/permissions';

import App from './App.vue';
import router from './router';

const app = createApp(App);

app.use(createPinia());

initConfig(app);

useSetPageTitle();

app.use(router).use(PermissionPlugin).mount('#app');
