<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import Conversation from '@/components/conversation/Conversation.vue';
import Welcome from '@/components/introduction/Welcome.vue';
import FirstLoginPopup from '@/components/layout/FirstLoginPopup.vue';
import MainLayout from '@/components/layout/MainLayout.vue';
import MunicipalPolicyPopup from '@/components/layout/MunicipalPolicyPopup.vue';
import { useThreadStore } from '@/stores/thread';
import { useUserStore } from '@/stores/user';
import FadeTransition from '@/transitions/FadeTransition.vue';

const threadStore = useThreadStore();
const userStore = useUserStore();
const route = useRoute();
const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true; // needed for deeplinks to threads
  route.params.thread_uuid && threadStore.fetchMessages(route.params.thread_uuid);
});

const showWelcome = computed(() => isMounted.value && !threadStore.hasConversationStarted && !threadStore.threadError);
</script>

<template>
  <Suspense>
    <MainLayout :showFooter="showWelcome">
      <FadeTransition :duration="250" mode="out-in">
        <Welcome v-if="showWelcome" />
        <Conversation v-else />
      </FadeTransition>
      <FirstLoginPopup />
      <MunicipalPolicyPopup v-if="!userStore.excludedFromContextOrganisation()" />
    </MainLayout>
    <template #fallback> Loading... </template>
  </Suspense>
</template>
