<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';

import SourceBreadcrumbs from '@/components/conversation/response/source/SourceBreadcrumbs.vue';
import { Source } from '@/models/Message';
import { useLocaleTranslator } from '@/translations';

import ExternalGasFields from './ExternalGasFields.vue';

interface Props {
  sources: Source[] | undefined;
}

defineProps<Props>();

const { translate } = useLocaleTranslator();

const ground_truth_sources = defineModel<string[]>('ground_truth_sources');
const gas_ids = defineModel<string[]>('external_gas_ids');

const get_source_slug = (source: Source) => {
  return `${source.content_type}_${source.content_id}`;
};

const toggleSource = (source: Source) => {
  if (!ground_truth_sources.value) {
    return;
  }

  const index = ground_truth_sources.value.findIndex((selectedSource) => selectedSource === get_source_slug(source));

  if (index === -1) {
    ground_truth_sources.value.push(get_source_slug(source));
  } else {
    ground_truth_sources.value.splice(index, 1);
  }
};
</script>
<template>
  <div>
    <p class="title">{{ translate('editorialFeedback.gasSourcesTitle') }}</p>
    <Stack
      v-for="source in sources"
      :key="`${source.content_type}_${source.content_id}`"
      :class="['gas-source-block', ground_truth_sources?.includes(get_source_slug(source)) && 'selected']"
      :gap="0"
      @click="toggleSource(source)"
    >
      <Stack :justifyContent="Position.Between" :gap="0">
        <Cluster class="title" :alignItems="Position.Center" :gap="0.5">
          <input type="checkbox" name="option" :checked="ground_truth_sources?.includes(get_source_slug(source))" />
          {{ source.title }}
        </Cluster>
        <SourceBreadcrumbs :breadcrumbs="source.breadcrumbs" :contentType="source.content_type" readonly />
      </Stack>
    </Stack>
    <label class="title">{{ translate('editorialFeedback.externalGasFieldsTitle') }}</label>
    <ExternalGasFields :onAddGasIds="(ids: string[]) => (gas_ids = ids)" :values="gas_ids" />
  </div>
</template>

<style scoped lang="scss">
p.title {
  margin-bottom: 0.7rem;
  display: block;
}

.source-blocks-tabs {
  margin-bottom: 1rem;
  border-bottom: 1px solid $wk-gray-tint4;
  .tab-header {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 20px 20px 0 0;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.2s;
    &.active {
      background-color: $wk-blue-tint5;
    }
  }
}
.gas-sources-wrapper {
  padding: 1rem;
  border: 1px solid $wk-gray-tint4;
}
.gta_textarea {
  width: 100%;
  background-color: $wk-gray-tint6;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
  resize: vertical;
}

.gas-source-block {
  border: 1px solid rgba($wk-blue-shade1, 0.2);
  border-radius: 0.5rem;
  padding: 6px 16px 6px 16px;
  margin-bottom: 0.5rem;
  cursor: pointer;

  //Add checkbox for .selected class (:before)
  &.selected {
    // background-color: $wk-blue-tint5;
  }
  .title {
    font-size: 14px;
    line-height: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
