import { computed } from 'vue';

import { DefaultEventData, EventData } from '@/models/Event';
import { getCurrentUnixTimestamp } from '@/services/utils/date';
import { useUserStore } from '@/stores/user';

export class Event<T> {
  private type: string = '';
  private data: Partial<T> = {};

  public setType(type: string): this {
    this.type = type;
    return this;
  }

  public getName(): string {
    return this.type;
  }

  public setData(data: Partial<T>): this {
    this.data = { ...this.data, ...data };
    return this;
  }

  public getData(): Partial<T> & EventData {
    return {
      ...this.data,
      ...this.getDefaultData()
    };
  }

  private getDefaultData(): DefaultEventData {
    const userStore = useUserStore();

    const userDetails = computed(() => userStore.userDetails);
    const selectedOrganisation = computed(() => userStore.selectedOrganisation);
    const domainCode = computed(() => userStore.lastChosenDomain?.productCode);

    return {
      domain_code: domainCode.value,
      unix_timestamp: getCurrentUnixTimestamp(),
      user_uuid: userDetails.value?.id,
      user_organisatie: selectedOrganisation.value?.name,
      user_vakgebied: userDetails.value?.jobArea,
      user_product: import.meta.env.VITE_APP_HOST,
      user_rol: selectedOrganisation.value?.role,
      user_functie: userDetails.value?.job
    };
  }
}
