<script setup lang="ts">
import Button from '@schulinck/components/ui/button/Button.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { computed, onMounted, Ref, ref } from 'vue';

import { EventNoData, EventType } from '@/models/Event';
import { UserDetailsDataInterface } from '@/models/UserDetails';
import { Event as TrackingEvent } from '@/services/tracking/event';
import { tracker } from '@/services/tracking/tracker';
import { useUserStore } from '@/stores/user';
import { useLocaleTranslator } from '@/translations';
const userDetails: Ref<UserDetailsDataInterface | null> = computed(() => userStore.userDetails);

import SimpleModal from './SimpleModal.vue';

const userStore = useUserStore();
const { translate } = useLocaleTranslator();

const showFirstLoginPopup = ref(false);
const disclaimerAcceptedEvent: TrackingEvent<EventNoData> = new TrackingEvent().setType(EventType.DisclaimerAccepted);

const isUsersFirstLogin = computed(() => userStore.isFirstLogin);

const acceptBtnClicked = () => {
  showFirstLoginPopup.value = false;
  tracker.push(disclaimerAcceptedEvent);
};

onMounted(() =>
  setTimeout(() => {
    showFirstLoginPopup.value = true;
  }, 500)
);
</script>

<template>
  <SimpleModal :show="isUsersFirstLogin && showFirstLoginPopup">
    <template #title>{{ translate('loginPopup.title', { firstName: userDetails?.firstName }) }}</template>
    <template #body>
      <Typography :variant="TypographyVariants.Paragraph" v-html="translate('loginPopup.body')"></Typography>
      <br />
      <ul>
        <li>
          <Typography :variant="TypographyVariants.Paragraph" v-html="translate('loginPopup.list.item.1')"></Typography>
        </li>
        <li>
          <Typography :variant="TypographyVariants.Paragraph" v-html="translate('loginPopup.list.item.2')"></Typography>
        </li>
        <li>
          <Typography :variant="TypographyVariants.Paragraph" v-html="translate('loginPopup.list.item.3')"></Typography>
        </li>
      </ul>
    </template>
    <template #footer>
      <Button :onClick="acceptBtnClicked">{{ translate('loginPopup.accept') }}</Button>
    </template>
  </SimpleModal>
</template>

<style scoped lang="scss">
ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin: 0;
}
</style>
