import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import { AxiosError } from 'axios';

import { ErrorReturnType } from '@/models/Error';
import { ApiErrorEventData, EventType } from '@/models/Event';
import { MessageType, Role } from '@/models/Message';
import { NotificationType } from '@/models/Notification';
import Product from '@/models/Product';
import router from '@/router';
import { Action } from '@/services/action/action';
import { Event } from '@/services/tracking/event';
import { tracker } from '@/services/tracking/tracker';
import { useNotificationStore } from '@/stores/notification';
import { useUserStore } from '@/stores/user';
import { useLocaleTranslator } from '@/translations';

const apiErrorEvent: Event<ApiErrorEventData> = new Event().setType(EventType.ApiError);
const { translate } = useLocaleTranslator();

const getHelpdeskUrl = (): string => {
  const userStore = useUserStore();
  const product: Product | undefined = userStore.userProducts.find(
    (product: Product): boolean => product?.code === userStore.lastChosenDomain?.productCode
  );
  return product ? `${product.url}/helpdesk/melding-maken` : '';
};

const handleApiError = (
  error: AxiosError,
  type: ErrorReturnType
): {
  trace_id: null;
  domain: null;
  thread_uuid: null;
  messages: unknown[];
  message: {
    role: Role;
    sources: null;
    loading: boolean;
    type: MessageType.Error;
    content: { body: string; actions: Action[] };
  };
} | null => {
  apiErrorEvent.setData({ error: error.response?.status || 'unknown' });

  tracker.push(apiErrorEvent);

  if (error.response?.status === 404) router.push({ name: 'NotFound' });

  if (type === ErrorReturnType.Message) {
    return {
      thread_uuid: null,
      trace_id: null,
      message: {
        role: Role.System,
        content: {
          body: translate(`error.${error.response?.status || 'unknown'}`),
          actions: [
            new Action({
              label: translate('action.helpdesk'),
              url: getHelpdeskUrl(),
              icon: IconNames.Mail
            })
          ]
        },
        sources: null,
        loading: false,
        type: MessageType.Error
      },
      messages: [],
      domain: null
    };
  } else if (type === ErrorReturnType.Notification) {
    useNotificationStore().showNotification(
      NotificationType.Error,
      translate('error.title'),
      translate(`error.${error.response?.status || 'unknown'}`),
      2500
    );
    return null;
  } else {
    return null;
  }
};

export { handleApiError };
