import { AxiosError, AxiosRequestConfig } from 'axios';

import { EventNoData, EventType } from '@/models/Event';
import router from '@/router';
import kbApi from '@/services/api/knowledgeBase/api';
import { isTokenExpired } from '@/services/auth/jwt/validator';
import { Event } from '@/services/tracking/event';
import { tracker } from '@/services/tracking/tracker';
import { stringToBool } from '@/services/utils/typeCasters';
import { useUserStore } from '@/stores/user';

const userLoggedOutEvent: Event<EventNoData> = new Event().setType(EventType.UserLoggedOut);

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  refreshTokenRequest?: boolean;
}
interface ApiRefreshResponse {
  JWT?: {
    access?: string;
    refresh?: string;
  };
}

class AuthService {
  private isAuth = false;
  private accessToken: string | null = null;
  private refreshToken: string | null = null;

  private isRefreshing = false;
  private refreshTokenPromise: Promise<string | null> | null = null;

  private isLoggingOut = false;

  public isAuthenticated(): boolean {
    if (!stringToBool(import.meta.env.VITE_USE_MOCK_API)) return !!this.getAccessToken();
    useUserStore().init();
    return true;
  }

  async refreshAccessToken(destinationUrl: string | null = null): Promise<string | null> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenPromise = new Promise<string | null>((resolve, reject) => {
        const refreshToken = this.getRefreshToken();

        if (!refreshToken || isTokenExpired(refreshToken)) {
          this.redirectToLogin(destinationUrl);
          return;
        }

        kbApi
          .get('assistant/auth/refresh-access-token', {
            headers: {
              'X-Authorization-Refresh-Token': refreshToken
            },
            refreshTokenRequest: true
          } as CustomAxiosRequestConfig)
          .then(async ({ data }: { data: ApiRefreshResponse }) => {
            if (data?.JWT?.refresh) {
              this.storeRefreshToken(data.JWT.refresh);
            }

            if (data?.JWT?.access) {
              this.storeAccessToken(data.JWT.access);

              this.isRefreshing = false;
              this.refreshTokenPromise = null;

              if (!this.isLoggingOut) {
                await useUserStore().init();
              }

              resolve(data.JWT.access);

              return;
            }

            this.redirectToLogin(destinationUrl);
          })
          .catch((error) => {
            this.isRefreshing = false;
            this.refreshTokenPromise = null;

            reject(error);
          });
      });
    }

    return this.refreshTokenPromise;
  }

  public redirectToLogin(destinationUrl: string | null = null): void {
    if (destinationUrl !== null) {
      this.setDestinationUrl(destinationUrl);
    }

    window.location.href = import.meta.env.VITE_KB_LOGIN_URL;
  }

  public async logout(userId: string): Promise<void> {
    this.isLoggingOut = true;
    await kbApi
      .get(`user/logout-user/${userId}`)
      .then(() => {
        tracker.push(userLoggedOutEvent);
        this.removeTokens();
        this.isAuth = false;
        window.location.assign(import.meta.env.VITE_KB_PRODUCTS_URL);
      })
      .catch(async (error: unknown) => {
        if ((error as AxiosError).response?.status === 403 || (error as AxiosError).response?.status === 401) {
          await router.push({ name: 'unauthorized' });
        } else {
          await router.push({ name: 'error' });
        }
        throw error;
      });
  }

  public getDestinationUrl(): string | null {
    return localStorage.getItem('destinationUrl');
  }

  public setDestinationUrl(destinationUrl: string): void {
    const route = router.resolve(destinationUrl);

    if (route.matched.length > 0) {
      localStorage.setItem('destinationUrl', route.path);
    }
  }

  public removeDestinationUrl(): void {
    localStorage.removeItem('destinationUrl');
  }

  /**
   * Below functions determine storage of JWT Tokens
   */
  public getAccessToken(): string | null {
    return this.accessToken;
  }

  public storeAccessToken(token: string): void {
    this.accessToken = token;
  }

  public getRefreshToken(): string | null {
    return this.refreshToken;
  }

  public storeRefreshToken(token: string): void {
    this.refreshToken = token;
  }

  public removeTokens(): void {
    this.accessToken = null;
    this.refreshToken = null;
  }
}

export default new AuthService();
