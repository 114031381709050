<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import { TagVariants } from '@schulinck/components/ui/tag/Tag.types';
import Tag from '@schulinck/components/ui/tag/Tag.vue';
import { computed } from 'vue';

import SourceBreadcrumbs from '@/components/conversation/response/source/SourceBreadcrumbs.vue';
import { ContentType, Source } from '@/models/Message';
import { useThreadStore } from '@/stores/thread';
import { useUserStore } from '@/stores/user';

const thread = useThreadStore();
const userStore = useUserStore();
interface Props {
  source: Source;
}

defineProps<Props>();

const contextOrganisationName = computed(() =>
  userStore.getContextOrganisationIdentifier(thread.contextOrganisationId)
);
</script>

<template>
  <div>
    <Stack :justifyContent="Position.Center" class="source-block" :gap="0">
      <Cluster class="tags" :alignItems="Position.Center" :gap="0.25" :wrap="true">
        <Tag
          v-if="source.content_type == ContentType.Guideline && contextOrganisationName"
          :variant="TagVariants.BlueTint"
          >{{ contextOrganisationName }}
        </Tag>
      </Cluster>
      <p class="title">{{ source.title }}</p>
      <SourceBreadcrumbs :breadcrumbs="source.breadcrumbs" :contentType="source.content_type" readonly />
    </Stack>
  </div>
</template>

<style scoped lang="scss">
.source-block {
  padding: 0.5rem 1rem;
  background-color: rgba($wk-blue-tint6, 0.4);
  border-radius: 0.5rem;
  border: solid 1px rgba($wk-blue-shade1, 0.2);
  cursor: pointer;
  transition: all 0.2s;
  height: 4.75rem;

  &:hover {
    border: solid 1px $wk-blue-shade1;
    background-color: $wk-blue-tint6;
    box-shadow: 0 0.25rem 0.75rem 0 rgba($wk-black, 0.1);
  }

  .tags {
    ::v-deep(.wk-tag) {
      background-color: transparent;
      border: 1px solid $wk-blue-shade1;
      padding: 0.1rem 0.5rem;
      color: $wk-blue-shade1;
      font-size: 0.6rem;
      margin-bottom: 0.1rem;
    }
  }

  .title {
    position: relative;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.3rem;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .content-type {
    font-size: 1rem;
    color: red;
  }
}
</style>
