import { Breadcrumb } from '@/models/Breadcrumbs';
import { ContentType, MessageApiInterface, Role, Source } from '@/models/Message';

const baseUrl: string = 'https://participatiewet.schulinck.nl/p/' as string;
export const mockBreadcrumbs: Breadcrumb[] = [
  {
    title: 'Bijzondere bijstand',
    permalink: `${baseUrl}9f3b84bd27a9461559ba000b43a71f061804234af58a5fe18ac4d88e7c55da7f50ac35f189f2a8047bd99d6f410dc58c`
  },
  {
    title: 'Jongeren van 18 t/m 20 jaar',
    permalink: `${baseUrl}9f3b84bd27a9461559ba000b43a71f061804234af58a5fe18ac4d88e7c55da7f4e39643a683d18d9cac039f088576b60`
  },
  {
    title: 'Jongeren niet in een inrichting',
    permalink: `${baseUrl}9f3b84bd27a9461559ba000b43a71f061804234af58a5fe18ac4d88e7c55da7faccb449ae11901d07567e9e1790b8d31`
  }
] as Breadcrumb[];

const generateMockSources = (usedCount: number = 3, unusedCount: number = 4): Source[] => {
  const sources: Source[] = [] as Source[];
  let contentId: number = 1000 as number;

  const createSource = (used: boolean): Source => ({
    content_type: ContentType.VerdictAnnotation,
    title: `Mock ${used ? 'used' : 'unused'} source title ${contentId}`,
    permalink: `${baseUrl}${contentId}`,
    content: '\n Mock source content',
    used_in_answer: used,
    trace_id: `46c884da-c5d1-4146-98af-f6d1fa0e-${contentId}`,
    content_id: contentId++,
    breadcrumbs: mockBreadcrumbs
  });

  for (let i = 0; i < usedCount; i++) {
    sources.push(createSource(true));
  }

  for (let i = 0; i < unusedCount; i++) {
    sources.push(createSource(false));
  }

  return sources;
};

export const MockAIMessage: MessageApiInterface = {
  trace_id: '46c884da-c5d1-4146-98af-f6d1fa0e1942',
  role: Role.AI,
  created_at: 1727438470,
  content: `<p>This is a mock response. \n\n Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
  Vestibulum vel lectus lorem. Fusce fringilla erat mauris, at eleifend nisl euismod a. Phasellus et elit ligula. 
  Aliquam faucibus pretium quam et efficitur. Cras eleifend velit magna, eget dictum ligula ullamcorper non. 
  Sed viverra, felis eget malesuada porttitor, ligula risus lacinia lacus, eget aliquam risus mauris eget massa. 
  Proin luctus eros sed quam vehicula porttitor. Aliquam erat volutpat. 
  Proin quis maximus nisi. Suspendisse justo metus, consectetur sed fringilla placerat, scelerisque vel arcu.</p>

  <p>Nullam rhoncus diam id mi porta consequat. Sed dapibus elit dui, pharetra hendrerit turpis gravida vitae. 
  Fusce et eros elit. Duis ornare venenatis pharetra. Quisque sagittis sagittis metus, sit amet malesuada nisi pretium id. 
  Integer at dui venenatis, tempor ex sagittis, volutpat erat. Quisque vitae dapibus nunc. 
  Integer consectetur nulla sapien, vel laoreet odio imperdiet sit amet. Curabitur a lectus ultricies, congue lorem eget, varius elit. 
  Ut sed massa in massa laoreet tincidunt quis quis nisl. Duis quam erat, blandit ut sapien eget, placerat condimentum est. 
  Proin feugiat auctor tortor ut hendrerit.</p>

  <p>Praesent id purus laoreet, iaculis magna id, sodales sem. Aliquam quis commodo augue. Donec eget tortor in diam pretium interdum vitae sed purus. 
  Aenean quis orci pretium orci maximus posuere. Phasellus libero tortor, convallis at nulla vel, congue gravida nisi. 
  Praesent mollis semper turpis, ut rutrum metus dignissim sit amet. Pellentesque urna felis, rutrum quis tellus nec, gravida aliquam ligula. 
  Aliquam sodales leo non libero porta viverra. Curabitur hendrerit eu justo sit amet viverra. Aliquam congue nunc sit amet consequat viverra.</p>`,
  conclusion: `<p>Integer consectetur nulla sapien, vel laoreet odio imperdiet sit amet. Curabitur a lectus ultricies, congue lorem eget, varius elit. 
  Ut sed massa in massa laoreet tincidunt quis quis nisl. Duis quam erat, blandit ut sapien eget, placerat condimentum est.</p>`,
  sources: generateMockSources()
};

export const MockHumanMessage: MessageApiInterface = {
  trace_id: null,
  role: Role.Human,
  created_at: 1727438437,
  content: 'Mock question',
  conclusion: null,
  sources: []
};

const threadUuid1: string = '46c884da-c5d1-4146-98af-f6d1fa0e1942' as string;
const threadUuid2: string = '46c884da-c5d1-4146-98af-f6d1fa0e1943' as string;
export const mockThreadUuids: string[] = [threadUuid1, threadUuid2];

export const mockThreads = [
  {
    title: 'Mock thread #1',
    thread_uuid: mockThreadUuids[0],
    updated_at: 1726580388
  },
  {
    title: 'Mock thread #2',
    thread_uuid: mockThreadUuids[1],
    updated_at: 1726558425
  }
];
