<script setup lang="ts">
import { useLocaleTranslator } from '@/translations';

interface Props {
  option: string;
  name?: string;
  selected: boolean;
}

withDefaults(defineProps<Props>(), {
  name: 'option'
});

const { translate } = useLocaleTranslator();
</script>

<template>
  <label :class="['option', selected && 'selected']">
    <input type="radio" :name="name" :value="option" :checked="selected" />
    {{ translate(option) }}
  </label>
</template>

<style scoped lang="scss">
.option {
  cursor: pointer;
  border-radius: 80px;
  border: 1px solid $wk-gray-tint4;
  background: $wk-white;
  font-size: 0.875rem;
  padding: 6px 16px 6px 12px;
  display: flex;
  gap: 6px;

  &:hover,
  &.selected {
    background-color: $wk-blue-tint5;
  }

  input {
    cursor: pointer;
  }
}
</style>
