export default {
  'large.placeholder': 'Vul hier je vraag in...',
  'large.fill_in_question': 'Vul een vraag in om verder te gaan',
  'large.length_exceeded': 'Deze vraag is te lang. Probeer het korter te houden.',
  'small.placeholder': 'Heb je nog een aanvulling of vervolgvraag?',
  'small.fill_in_question': 'Vul een aanvulling in om verder te gaan',
  'small.length_exceeded': 'Deze vervolgvraag is te lang. Probeer het korter te houden.',
  'select.product': 'Selecteer je vakgebied',
  'select.policy': 'Selecteer je beleidsorganisatie',
  'select.option.no_policy': 'Lokaal beleid uitsluiten',
  'select.tooltip.product': 'Uit welke kennisbank de informatie komt',
  'select.tooltip.policy': 'Lokaal beleid van %organisation% gebruiken',
  'select.tooltip.no_policy': 'Geen lokaal beleid gebruiken'
};
