<script setup lang="ts">
import Button from '@schulinck/components/ui/button/Button.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { computed, onMounted, ref } from 'vue';

import { stringToBool } from '@/services/utils/typeCasters';
import { useUserStore } from '@/stores/user';
import { useLocaleTranslator } from '@/translations';

import SimpleModal from './SimpleModal.vue';

const { translate } = useLocaleTranslator();

const showMunicipalPolicyPopup = ref(false);
const municipalPolicyPopupShown = ref(stringToBool(localStorage.getItem('municipalPolicyPopupShown')));
const isUsersFirstLogin = computed(() => useUserStore().isFirstLogin);

const closeBtnClicked = () => {
  showMunicipalPolicyPopup.value = false;
  localStorage.setItem('municipalPolicyPopupShown', 'true');
};

onMounted(() => {
  if (isUsersFirstLogin.value) {
    localStorage.setItem('municipalPolicyPopupShown', 'true');
  }

  setTimeout(() => {
    showMunicipalPolicyPopup.value = true;
  }, 500);
});
</script>

<template>
  <SimpleModal :show="showMunicipalPolicyPopup && !municipalPolicyPopupShown && !isUsersFirstLogin">
    <template #title>{{ translate('municipalPolicy.title') }}</template>
    <template #body>
      <Typography :variant="TypographyVariants.Paragraph" v-html="translate('municipalPolicy.body')"></Typography>
    </template>
    <template #footer>
      <Button :onClick="closeBtnClicked">{{ translate('municipalPolicy.close') }}</Button>
    </template>
  </SimpleModal>
</template>
