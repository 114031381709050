<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { AxiosError } from 'axios';
import { ref } from 'vue';

import { ErrorReturnType } from '@/models/Error';
import { EventType, ResponseFeedbackEventData } from '@/models/Event';
import { FeedbackFormValues, FeedbackValue, ThumbButtonsState, ThumbFeedbackType } from '@/models/Feedback';
import { MessageInterface } from '@/models/Message';
import { Permission } from '@/models/PermissionSet';
import { handleApiError } from '@/services/api/api';
import saApi from '@/services/api/assistant/api';
import { Event } from '@/services/tracking/event';
import { tracker } from '@/services/tracking/tracker';

import EditorialFeedbackButton from './EditorialFeedbackButton.vue';
import EditorialFeedbackForm from './form/editorial/EditorialFeedbackForm.vue';
import ThumbsDown from './form/elements/ThumbsDown.vue';
import ThumbsUp from './form/elements/ThumbsUp.vue';
import FeedbackForm from './form/FeedbackForm.vue';

interface Props {
  message: MessageInterface;
}

interface InputInterface {
  score_type: string;
  score_value: string;
  score_comment: string | null;
  trace_id: string;
}

const { message } = defineProps<Props>();

const activeButton = ref<ThumbButtonsState>(ThumbButtonsState.None);
const thumbsDownReference = ref<HTMLElement>();
const gasReference = ref<HTMLElement>();
const showFeedbackForm = ref(false);
const showEditorialFeedbackForm = ref(false);
const hasEditorialFeedbackFormData = ref(false);
const responseFeedbackEvent: Event<ResponseFeedbackEventData> = new Event().setType(EventType.AnswerFeedback);

const handleButtonClick = (button: ThumbButtonsState) => {
  activeButton.value = button;
  sendEventToAnalytics(button);

  if (button === ThumbButtonsState.Up) {
    sendDataToBackend(FeedbackValue[activeButton.value], null);
    showFeedbackForm.value = false;
  } else {
    showEditorialFeedbackForm.value = false;
    showFeedbackForm.value = button === ThumbButtonsState.Down;
  }

  return false;
};

const handleGasButtonClick = () => {
  showFeedbackForm.value = false;
  showEditorialFeedbackForm.value = !showEditorialFeedbackForm.value;
};

const sendEventToAnalytics = (button: ThumbButtonsState) => {
  responseFeedbackEvent.setData({
    trace_id: message.trace_id,
    value: FeedbackValue[button]
  });
  tracker.push(responseFeedbackEvent);
};

const sendDataToBackend = async (scoreValue: string, formValues: FeedbackFormValues | null) => {
  const input: InputInterface = {
    score_type: ThumbFeedbackType.Answer,
    score_value: scoreValue,
    score_comment: formValues ? (formValues.text as string) : null,
    trace_id: message.trace_id
  };

  await saApi
    .post('/chat/user-feedback', { input: input })
    .then(() => {
      return false;
    })
    .catch((error: AxiosError) => {
      handleApiError(error, ErrorReturnType.Notification);
    });

  return false;
};

const onHideFeedbackForm = () => {
  showFeedbackForm.value = false;
};

const onHideEditorialFeedbackForm = () => {
  showEditorialFeedbackForm.value = false;
};

const onSubmitFeedbackForm = (formValues: FeedbackFormValues) => {
  showFeedbackForm.value = false;
  sendDataToBackend(FeedbackValue[ThumbButtonsState.Down], formValues);
};
</script>

<template>
  <Stack :gap="0">
    <Cluster class="feedback" :gap="0">
      <Typography class="label" :variant="TypographyVariants.Paragraph">
        <slot />
      </Typography>
      <ThumbsUp :clickedButton="activeButton" :onButtonClicked="handleButtonClick" />
      <ThumbsDown
        ref="thumbsDownReference"
        :clickedButton="activeButton"
        :focussed="showFeedbackForm"
        :onButtonClicked="handleButtonClick"
      />
      <EditorialFeedbackButton
        v-if="$hasPermission(Permission.SourceFeedback)"
        ref="gasReference"
        :onClick="handleGasButtonClick"
        :focussed="showEditorialFeedbackForm"
        :hasData="hasEditorialFeedbackFormData"
      />
    </Cluster>
    <FeedbackForm
      :show="showFeedbackForm"
      :onHide="onHideFeedbackForm"
      :onSubmit="onSubmitFeedbackForm"
      :reference="thumbsDownReference"
      :traceId="message.trace_id"
    />
    <EditorialFeedbackForm
      v-if="$hasPermission(Permission.SourceFeedback)"
      :show="showEditorialFeedbackForm"
      :reference="gasReference"
      :onHide="onHideEditorialFeedbackForm"
      :sources="message.sources || []"
      :traceId="message.trace_id"
      :onHasData="(hasData: boolean) => (hasEditorialFeedbackFormData = hasData)"
    />
  </Stack>
</template>

<style scoped lang="scss">
.feedback {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  font-size: 0.75rem;
  color: $wk-gray-tint1;
  position: relative;

  .label {
    margin-right: 0.5rem;
  }
}
</style>
