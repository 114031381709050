import { DomainCode, getDomainName } from '@/models/Domain';
import { ProductDataInterface } from '@/models/Product';
import { UserDetailsDataInterface } from '@/models/UserDetails';

const organisationId1: string = '3b417f145e998bbf269fed0277e5508d' as string;
const organisationId2: string = '19a8fb779d526afdd672598652a33e62' as string;
export const mockOrganisationIds: string[] = [organisationId1, organisationId2];

const permissionsToken1: string =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJvcmdhbmlzYXRpb25JZCI6IjNiNDE3ZjE0NWU5OThiYmYyNjlmZWQwMjcwZTU1MDhkIiwiYWxsb3dlZERvbWFpbnMiOlsicHciLCJqZXVnZCJdLCJwZXJtaXNzaW9ucyI6eyJ0aHJlYWRSZWFkVXNlciI6dHJ1ZSwidGhyZWFkUmVhZE9yZ2FuaXNhdGlvbiI6dHJ1ZSwidGhyZWFkUmVhZEFsbCI6dHJ1ZSwidGhyZWFkQ3JlYXRlVXNlciI6dHJ1ZSwidGhyZWFkRGVsZXRlVXNlciI6ZmFsc2UsInNvdXJjZUZlZWRiYWNrIjp0cnVlLCJhbnN3ZXJGZWVkYmFjayI6dHJ1ZSwiamlyYVNvdXJjZVJlYWQiOnRydWUsIm5vdEFuc3dlcmFibGVSZWFkIjp0cnVlfX0.X9ZJ6N11IpjEfKF1OprLOazVu6YfKEvip5bHWxaaUm4' as string;
const permissionsToken2: string =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJvcmdhbmlzYXRpb25JZCI6IjE5YThmYjc3OWQ1MjZhZmRkNjcyNTk4NjUyYTMzZTYyIiwiYWxsb3dlZERvbWFpbnMiOlsicHciLCJ3bW8iLCJqZXVnZCJdLCJwZXJtaXNzaW9ucyI6eyJ0aHJlYWRSZWFkVXNlciI6ZmFsc2UsInRocmVhZFJlYWRPcmdhbmlzYXRpb24iOmZhbHNlLCJ0aHJlYWRSZWFkQWxsIjpmYWxzZSwidGhyZWFkQ3JlYXRlVXNlciI6ZmFsc2UsInRocmVhZERlbGV0ZVVzZXIiOmZhbHNlLCJzb3VyY2VGZWVkYmFjayI6ZmFsc2UsImFuc3dlckZlZWRiYWNrIjpmYWxzZSwiamlyYVNvdXJjZVJlYWQiOmZhbHNlLCJub3RBbnN3ZXJhYmxlUmVhZCI6ZmFsc2V9fQ.7TEczBx9OjoPyAwhNFYS1PAGly-ylQkQRji16YktgTs' as string;
export const mockPermissionTokens: string[] = [permissionsToken1, permissionsToken2];

export const mockUserDetails: UserDetailsDataInterface = {
  id: 'b90e010b-bc36-4666-9a32-9b2dfecba16f',
  salutation: 'Doe, John',
  initials: 'JD',
  firstName: 'John',
  lastName: 'Doe',
  job: 'Medewerker Sociale Zaken',
  jobArea: 'Werk en Inkomen',
  lastLoggedInKnowledgeBaseOrganisationId: mockOrganisationIds[0],
  lastLoggedInKnowledgeBaseProductCode: DomainCode.Participatiewet,
  lastLoginDate: '2025-01-16T14:08:48+01:00'
};

export const mockOrganisations = [
  {
    id: mockOrganisationIds[0],
    name: 'Standaardeditie',
    role: 'Assistent Redacteur',
    permissionsToken: mockPermissionTokens[0],
    _embedded: {
      domains: [
        {
          name: getDomainName(DomainCode.Participatiewet),
          productCode: DomainCode.Participatiewet
        },
        {
          name: getDomainName(DomainCode.Jeugd),
          productCode: DomainCode.Jeugd
        }
      ],
      permissions: {
        threadReadUser: true,
        threadReadOrganisation: true,
        threadReadAll: true,
        threadCreateUser: true,
        threadDeleteUser: false,
        sourceFeedback: true,
        answerFeedback: true,
        jiraSourceRead: true,
        notAnswerableRead: true
      }
    }
  },
  {
    id: mockOrganisationIds[1],
    name: 'Gemeente Juinen',
    role: '',
    permissionsToken: mockPermissionTokens[1],
    _embedded: {
      domains: [
        {
          name: getDomainName(DomainCode.Participatiewet),
          productCode: DomainCode.Participatiewet
        },
        {
          name: getDomainName(DomainCode.Wmo),
          productCode: DomainCode.Wmo
        },
        {
          name: getDomainName(DomainCode.Jeugd),
          productCode: DomainCode.Jeugd
        }
      ],
      permissions: {
        threadReadUser: false,
        threadReadOrganisation: false,
        threadReadAll: false,
        threadCreateUser: false,
        threadDeleteUser: false,
        sourceFeedback: false,
        answerFeedback: false
      }
    }
  }
];

export const mockProducts: ProductDataInterface[] = [
  {
    code: DomainCode.Participatiewet,
    title: 'Schulinck Participatiewet',
    url: 'https://releasesprint141--participatiewet.acc1.schulinck.nl'
  },
  {
    code: DomainCode.Jeugd,
    title: 'Schulinck Jeugd',
    url: 'https://releasesprint141--jeugd.acc1.schulinck.nl'
  },
  {
    code: DomainCode.Wmo,
    title: 'Schulinck Wmo',
    url: 'https://releasesprint141--wmo.acc1.schulinck.nl'
  }
];

export const mockUserData = {
  _embedded: {
    userDetails: mockUserDetails,
    organisations: mockOrganisations,
    products: mockProducts
  }
};
