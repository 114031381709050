import { AxiosError } from 'axios';
import { defineStore, StoreDefinition } from 'pinia';
import { computed, ComputedRef, Ref, ref } from 'vue';

import { useSetPageTitle } from '@/hooks/useSetPageTitle';
import { DomainCode } from '@/models/Domain';
import { ErrorMessage, ErrorReturnType } from '@/models/Error';
import { DurationCustomerRequestEventData, EventType, QuestionAskedEventData } from '@/models/Event';
import { MessageApiInterface, MessageDataInterface, MessageInterface, Role, Source } from '@/models/Message';
import { QuestionType } from '@/models/Question';
import router from '@/router';
import { handleApiError } from '@/services/api/api';
import saApi from '@/services/api/assistant/api';
import { Event } from '@/services/tracking/event';
import { tracker } from '@/services/tracking/tracker';
import { getCurrentUnixTimestamp } from '@/services/utils/date';
import { useThreadsStore } from '@/stores/threads';
import { useUserStore } from '@/stores/user';
export interface InvokeInput {
  question: string;
  domain: DomainCode | null;
  thread_uuid: string | null;
  organisation_name: string | null;
  job: string | null;
  context_organisation_id: string | null;
}

export interface MessagesInput {
  thread_uuid: string;
}

export const useThreadStore: StoreDefinition = defineStore('thread', () => {
  const uuid: Ref<string | null> = ref(null);
  const traceId: Ref<string | null> = ref(null);
  const created_at: Ref<number | null> = ref(null);
  const domain: Ref<DomainCode | null> = ref(null);
  const contextOrganisationId: Ref<string | null> = ref(null);
  const messages: Ref<MessageInterface[]> = ref([]);
  const readOnly: Ref<boolean> = ref(false);
  const loading: Ref<boolean> = ref(false);
  const threadError: Ref<boolean> = ref(false);

  const hasConversationStarted: ComputedRef<boolean> = computed((): boolean => messages.value.length > 0);
  const hasAssistantResponded: ComputedRef<boolean> = computed(
    (): boolean => messages.value.length > 1 && !messages.value[1].loading
  );

  const questionAskedEvent: Event<QuestionAskedEventData> = new Event().setType(EventType.QuestionAsked);
  const durationCustomerRequestEvent: Event<DurationCustomerRequestEventData> = new Event().setType(
    EventType.DurationCustomerRequest
  );

  const userStore = useUserStore();

  const fullBreadcrumbString = (source: Source) => source.breadcrumbs?.join(' > ') + ' > ' + source.title;

  const sortAndBundleSources = (sources: Source[]): Source[] => {
    if (sources === null) return [];

    // Separate sources into two groups
    const usedSources = sources.filter((source) => source.used_in_answer);
    const unusedSources = sources.filter((source) => !source.used_in_answer);

    const bundleSources = (sources: Source[]) => {
      sources.map((source: Source, index: number) => {
        sources
          .map((src, idx) => (fullBreadcrumbString(src) === fullBreadcrumbString(source) ? idx : ''))
          .filter((idx) => idx !== '')
          .reverse()
          .forEach((duplicateIndex: number) => {
            if (duplicateIndex === index) return;

            source.content += `<p><br/> &mldr;&mldr; </p><p><br/>${sources[duplicateIndex].content}</p>`;
            sources.splice(duplicateIndex, 1);
          });

        return source;
      });
    };

    bundleSources(usedSources);
    bundleSources(unusedSources);

    return [...usedSources, ...unusedSources];
  };

  const addQuestion = async (
    question: string,
    domainCode: DomainCode | null,
    ContextOrganisation: string | null
  ): Promise<void> => {
    if (!domain.value) domain.value = domainCode;
    if (!created_at.value) created_at.value = getCurrentUnixTimestamp();
    if (!contextOrganisationId.value) contextOrganisationId.value = ContextOrganisation;

    useSetPageTitle(question);

    const message: MessageInterface = {
      role: Role.Human,
      content: { body: question },
      sources: null,
      loading: false,
      trace_id: ''
    };
    messages.value.push(message);

    const questionCount: number = messages.value.filter(
      (message: MessageInterface): boolean => message.role === Role.Human
    ).length;
    questionAskedEvent.setData({
      thread_uuid: uuid.value,
      type: questionCount > 1 ? QuestionType.FollowUp : QuestionType.Initial,
      count: questionCount
    });
    tracker.push(questionAskedEvent);

    await addAssistantResponse(question);
  };

  const addAssistantResponse = async (question: string): Promise<void> => {
    loading.value = true;
    const threadsStore = useThreadsStore();
    const startTime: number = performance.now();
    messages.value.push({
      role: Role.AI,
      content: null,
      sources: null,
      loading: true,
      trace_id: ''
    });
    const index: number = messages.value.length - 1;
    const input: InvokeInput = {
      question: question,
      domain: domain.value,
      context_organisation_id: contextOrganisationId.value === '-' ? null : contextOrganisationId.value,
      thread_uuid: uuid.value || null,
      organisation_name: userStore.selectedOrganisation?.name || '',
      job: userStore.userDetails?.job || ''
    };

    const response = await saApi
      .post('/chat/invoke', { input: input })
      .then(({ data: { output } }) => {
        const message: MessageDataInterface = mapApiResponseToMessageFormat(output.message);
        message.sources = sortAndBundleSources(message.sources);
        return {
          thread_uuid: output.thread_uuid,
          trace_id: output.message.trace_id,
          message: message
        };
      })
      .catch((error: AxiosError) => {
        threadError.value = true;
        return handleApiError(error, ErrorReturnType.Message) as ErrorMessage;
      })
      .finally(() => {
        const endTime: number = performance.now();
        const duration: number = endTime - startTime;
        durationCustomerRequestEvent.setData({
          thread_uuid: uuid.value,
          duration_ms: duration
        });
        tracker.push(durationCustomerRequestEvent);
        messages.value[index].loading = loading.value = false;
      });

    traceId.value = response.trace_id;

    const newUpdateAt = getCurrentUnixTimestamp();
    if (uuid.value === null && response.thread_uuid !== null) {
      uuid.value = response.thread_uuid;
      threadsStore.addThread({
        title: messages.value[0].content?.body,
        thread_uuid: uuid.value,
        updated_at: newUpdateAt
      });
      await router.push({ path: `/chat/${uuid.value}` });
    } else {
      threadsStore.updateThread(uuid.value, newUpdateAt);
    }
    messages.value[index] = { ...messages.value[index], ...response.message };
  };

  const fetchMessages = async (threadUuid: string): Promise<void> => {
    loading.value = true;
    uuid.value = threadUuid;

    const input: MessagesInput = { thread_uuid: threadUuid };

    const response = await saApi
      .post('/chat/messages', { input: input })
      .then(({ data: { output } }) => {
        const messages: MessageDataInterface[] = output.messages.map(mapApiResponseToMessageFormat);
        messages.forEach((message) => {
          message.sources = sortAndBundleSources(message.sources);
        });
        return {
          messages: messages,
          domain: output.domain as DomainCode,
          contextOrganisationId: output.context_organisation_id ?? null,
          readOnly: output.read_only
        };
      })
      .catch((error: AxiosError) => {
        threadError.value = true;
        return handleApiError(error, ErrorReturnType.Message) as ErrorMessage;
      })
      .finally(() => (loading.value = false));

    for (const message of response.messages) messages.value.push({ ...message, loading: false });

    created_at.value = messages.value[0]?.created_at || null;
    domain.value = response.domain;
    readOnly.value = response.readOnly || !userStore.isDomainPermitted(response.domain);
    contextOrganisationId.value = response.contextOrganisationId;

    useSetPageTitle(messages.value[0].content?.body);
  };

  const mapApiResponseToMessageFormat = (message: MessageApiInterface): MessageDataInterface => {
    return {
      ...message,
      context_organisation_id: null,
      content: {
        body: message.content,
        conclusion: message.conclusion,
        actions: []
      }
    };
  };

  const resetThread = (): void => {
    uuid.value = null;
    created_at.value = null;
    domain.value = null;
    messages.value = [];
    readOnly.value = false;
    loading.value = false;
    threadError.value = false;
    contextOrganisationId.value = null;
    userStore.setLastChosenContextOrganisationId(null);
  };

  const openThread = async (threadUuid: string): Promise<void> => {
    loading.value = true;
    resetThread();
    await router.push({ path: `/chat/${threadUuid}` });
    await fetchMessages(threadUuid);
  };

  return {
    uuid,
    traceId,
    created_at,
    domain,
    contextOrganisationId,
    messages,
    readOnly,
    loading,
    threadError,
    hasConversationStarted,
    hasAssistantResponded,
    addQuestion,
    fetchMessages,
    resetThread,
    openThread
  };
});
