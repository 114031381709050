import Resource, { HALResourceDataInterface } from '@schulinck/lib/hateoas/resources/Resource';

import { Breadcrumb } from '@/models/Breadcrumbs';
import { Action } from '@/services/action/action';

export enum Role {
  Human = 'human',
  AI = 'ai',
  System = 'system'
}

export enum ContentType {
  Guideline = 'guideline',
  MunicipalDocument = 'municipal_document',
  RegulationElement = 'regulation_element',
  ThematicImmersion = 'thematic_immersion',
  Verdict = 'verdict',
  VerdictAnnotation = 'verdict_annotation',
  FrequentlyAskedQuestion = 'frequently_asked_question',
  JiraThread = 'jira_thread'
}

export interface Source {
  trace_id: string;
  content_id: number;
  content_type: ContentType;
  title: string;
  permalink: string;
  content: string;
  breadcrumbs: Breadcrumb[] | null;
  used_in_answer: boolean;
}

export interface MessageInterface {
  uuid?: string;
  trace_id: string | null;
  role: Role;
  created_at?: number;
  content: MessageContentInterface | null;
  sources: Source[] | null;
  loading: boolean;
  type?: string;
}

export interface MessageContentInterface {
  body: string;
  conclusion: string | null;
  actions?: Action[];
}

export interface MessageApiInterface {
  trace_id: string | null;
  role: Role;
  created_at: number;
  content: string;
  conclusion: string | null;
  sources: Source[];
}

export interface MessageDataInterface extends HALResourceDataInterface {
  readonly trace_id: string | null;
  readonly role: Role;
  readonly created_at: number;
  readonly content: MessageContentInterface | null;
  sources: Source[];
  readonly type?: string;
  context_organisation_id: string | null;
}

export enum MessageType {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning'
}

export default class Message extends Resource {
  public role: Role;
  public created_at: number;
  public content: MessageContentInterface | null;
  public sources: Source[];
  public loading: boolean;
  public context_organisation_id: string | null;

  constructor(data: MessageDataInterface) {
    super(data);

    this.role = data.role;
    this.created_at = data.created_at;
    this.content = data.content;
    this.sources = data.sources;
    this.loading = false;
    this.context_organisation_id = data.context_organisation_id;
  }
}
