<script setup lang="ts">
import Clickable from '@schulinck/components/ui/clickable/Clickable.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import { computed } from 'vue';

import { ThumbButtonsState } from '@/models/Feedback';

interface Props {
  clickedButton: ThumbButtonsState;
  focussed?: boolean;
  onButtonClicked: (state: ThumbButtonsState) => void;
}

const props = defineProps<Props>();

const thumbsDownActive = computed(() => props.clickedButton === ThumbButtonsState.Down);
const thumbsDownButtonClicked = () =>
  props.onButtonClicked(thumbsDownActive.value ? ThumbButtonsState.None : ThumbButtonsState.Down);
</script>

<template>
  <div :class="['thumbsdown', thumbsDownActive && focussed && 'active']">
    <Clickable ref="thumbsDownReference" class="down" @click.stop.prevent="thumbsDownButtonClicked">
      <Icon :filled="thumbsDownActive" :class="[thumbsDownActive && 'active']" :name="IconNames.ThumbsDown" />
    </Clickable>
  </div>
</template>

<style scoped lang="scss">
.thumbsdown {
  .wk-clickable {
    position: relative;
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    border-radius: 50%;

    &:hover {
      background-color: $wk-blue-tint6;
    }

    i {
      color: $wk-blue-shade1;
      margin: 0;

      &.active {
        color: $wk-red;
        animation: bounceDown 0.2s ease-in-out forwards;
      }
    }
  }

  &.active {
    .wk-clickable {
      background-color: $wk-blue-tint5;
    }
  }

  ::v-deep(.wk-notification) {
    position: absolute;
    right: 0;
    top: 0;
  }
}

@keyframes bounceDown {
  0% {
    transform: translateY(0) rotate(0);
  }
  33% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(8px) rotate(-20deg);
  }
  75% {
    transform: translateY(4px) rotate(-15deg);
  }
}
</style>
