<script setup lang="ts">
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import TooltipInteractive from '@schulinck/components/ui/tooltip-interactive/TooltipInteractive.vue';
import { computed, ref, watch } from 'vue';

import ContextSelectors from '@/components/conversation/prompt/ContextSelectors.vue';
import SubmitButtonWithTooltip from '@/components/conversation/prompt/SubmitButtonWithTooltip.vue';
import { EventNoData, EventType } from '@/models/Event';
import { Event as TrackingEvent } from '@/services/tracking/event';
import { tracker } from '@/services/tracking/tracker';
import { useThreadStore } from '@/stores/thread';
import { useUserStore } from '@/stores/user';
import { useLocaleTranslator } from '@/translations';

import Counter from './Counter.vue';
import DomainTooltip from './DomainTooltip.vue';
import GrowingTextarea from './GrowingTextarea.vue';

const threadStore = useThreadStore();
const inputValue = ref<string>('');
const userStore = useUserStore();
const domainSelectorReference = ref<HTMLElement>();
const showDomainTooltip = ref(false);
const enableShakeClass = ref<boolean>(false);
const { translate } = useLocaleTranslator();
const maxPromptLength = 2000;
const maxPromptLengthBuffer = 500;

const currentDomainCode = computed(() => (userStore.lastChosenDomain ? userStore.lastChosenDomain.productCode : null));
const currentContextOrganisationId = computed(() => userStore.lastChosenContextOrganisationId);

const promptLengthExceeded = computed(() => inputValue.value.length > maxPromptLength);
const promptLengthWarning = computed(() => inputValue.value.length > maxPromptLength - maxPromptLengthBuffer);
const preventSubmit = computed(() => inputValue.value.length === 0 || promptLengthExceeded.value);
const promptLengthExceededEvent: TrackingEvent<EventNoData> = new TrackingEvent().setType(
  EventType.PromptLengthExceeded
);

const tooltipTitle = computed(() => {
  if (promptLengthExceeded.value) {
    return translate('prompt.large.length_exceeded');
  }
  return preventSubmit.value ? translate('prompt.large.fill_in_question') : '';
});

const handleInputChange = (value: string) => (inputValue.value = value);
const hideDomainTooltip = () => (showDomainTooltip.value = false);

const submitPrompt = (event: Event) => {
  event.preventDefault();

  if (preventSubmit.value) {
    enableShakeClass.value = true;
    setTimeout(() => {
      enableShakeClass.value = false;
    }, 1500);
    return;
  }
  if (!currentDomainCode.value) {
    showDomainTooltip.value = true;
    return;
  }
  threadStore.addQuestion(inputValue.value, currentDomainCode.value, currentContextOrganisationId.value);
  inputValue.value = '';
};

watch(promptLengthExceeded, () => tracker.push(promptLengthExceededEvent));
</script>

<template>
  <div :class="['large-prompt', enableShakeClass && 'shake']">
    <GrowingTextarea
      :inputValue="inputValue"
      :placeholder="translate('prompt.large.placeholder')"
      :onUpdate="handleInputChange"
      :onSubmit="submitPrompt"
      minHeight="2.5rem"
      maxHeight="40vh"
    />
    <div class="large-prompt-actions">
      <ContextSelectors :errorDomainSelect="showDomainTooltip" class="context-selectors" />
      <div class="right-actions">
        <Counter
          v-if="inputValue.length >= maxPromptLength - maxPromptLengthBuffer"
          :count="inputValue.length"
          :max="maxPromptLength"
          :warning="promptLengthWarning"
          :exceeded="promptLengthExceeded"
        />
        <SubmitButtonWithTooltip
          :preventSubmit="preventSubmit"
          :submitPrompt="submitPrompt"
          :tooltipTitle="tooltipTitle"
          :submitButtonIcon="IconNames.ArrowRight"
        />
      </div>
    </div>
    <TooltipInteractive
      v-if="domainSelectorReference && showDomainTooltip"
      :offset="20"
      :onClose="hideDomainTooltip"
      :reference="domainSelectorReference"
    >
      <template #buttons><span></span></template>
      <DomainTooltip :onCancel="hideDomainTooltip" />
    </TooltipInteractive>
  </div>
</template>

<style scoped lang="scss">
.large-prompt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem 1rem 0.5rem 1rem;
  margin: 1rem 0 0.5rem 0;
  gap: 0.5rem;
  background-color: $wk-blue-tint6;
  border-radius: 0.5rem;
  outline: solid 1px $wk-blue-tint5;

  &:focus-within {
    outline: solid 1px rgba($wk-blue-shade1, 0.2);
  }

  &.shake {
    @include shake-animation;
  }

  .large-prompt-actions {
    display: flex;
    justify-content: space-between;
    align-items: end;
    font-size: 0.875rem;
    width: 100%;

    .context-selectors {
      margin-left: -0.75rem;
    }

    ::v-deep(.wk-field-is-disabled) {
      opacity: 1;
      .wk-select-icon {
        display: none;
      }
    }

    .right-actions {
      display: flex;
      align-items: center;

      .counter {
        margin-right: 1rem;
      }
    }
  }
}
</style>
<style lang="scss">
.wk-dropdown {
  margin-top: 0.25rem;
  border-top-width: 1px !important;
  width: auto !important;
}
</style>
