export default {
  'response.label': 'Wat vind je van dit antwoord?',
  'source.label': 'Wat vind je van deze bron?',
  title: 'Help ons verbeteren',
  explanationTitle: 'We leren graag van je feedback...',
  explanationPlaceholder: 'Je feedback...',

  incorrectSources: 'Verkeerde bron(nen)',
  deprecatedSources: 'Oude of verouderde bron(nen)',
  incorrectResponse: 'Respons incorrect',
  other: 'Anders...',

  submit: 'Versturen',
  cancel: 'Formulier sluiten',

  notificationTitle: 'Bedankt voor je feedback!',
  notificationContent: 'Je feedback is ontvangen en we gaan er mee aan de slag'
};
