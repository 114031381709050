import { AxiosError } from 'axios';
import { defineStore, StoreDefinition } from 'pinia';
import { computed, ComputedRef, Ref, ref } from 'vue';

import Domain, { DomainCode } from '@/models/Domain';
import Organisation, { ContextOrganisation } from '@/models/Organisation';
import PermissionSet from '@/models/PermissionSet';
import Product from '@/models/Product';
import User, { UserDataInterface } from '@/models/User';
import { UserDetailsDataInterface } from '@/models/UserDetails';
import router from '@/router';
import kbApi from '@/services/api/knowledgeBase/api';
import AuthService from '@/services/auth/authService';

export const useUserStore: StoreDefinition = defineStore('user', () => {
  const user: Ref<User | null> = ref(null);
  const productCode: Ref<string | null> = ref(localStorage.getItem('productCode'));
  const storedContextOrganisationId: Ref<string | null> = ref(null);

  const permissions: Ref<PermissionSet | null> = ref(null);

  const isAuthenticated: ComputedRef<boolean> = computed<boolean>(() => AuthService.isAuthenticated());

  const userDetails: ComputedRef<UserDetailsDataInterface | null> = computed<UserDetailsDataInterface | null>(() => {
    return user.value ? user.value.userDetails : null;
  });

  const isFirstLogin: ComputedRef<boolean> = computed<boolean>(() => userDetails.value?.lastLoginDate === null);

  const userOrganisations: ComputedRef<Organisation[]> = computed<Organisation[]>(() => {
    return user.value && user.value.organisations ? user.value.organisations.items : [];
  });

  const contextOrganisationOverrides: Record<string, ContextOrganisation[]> = {
    '19a8fb779d526afdd672598652a33e62': [
      {
        id: 'f12d5f785262bdac46239fc024a6eb33',
        name: "Gemeente 's-Hertogenbosch"
      },
      {
        id: '5f54a223befc3c576f0d106d7b648d13',
        name: 'Gemeente Eindhoven'
      },
      {
        id: '82f94fb15fdc52c3034d3d993438d8d7',
        name: 'Gemeente Kerkrade'
      },
      {
        id: '9d5e52d965479ab6db7c6fa11b7108f8',
        name: 'Gemeente Súdwest-Fryslân'
      }
    ],
    '3b417f145e998bbf269fed0270e5508d': [
      {
        id: 'f12d5f785262bdac46239fc024a6eb33',
        name: "Gemeente 's-Hertogenbosch"
      },
      {
        id: '5f54a223befc3c576f0d106d7b648d13',
        name: 'Gemeente Eindhoven'
      },
      {
        id: '82f94fb15fdc52c3034d3d993438d8d7',
        name: 'Gemeente Kerkrade'
      },
      {
        id: '9d5e52d965479ab6db7c6fa11b7108f8',
        name: 'Gemeente Súdwest-Fryslân'
      }
    ]
  };

  const selectedOrganisation: ComputedRef<Organisation | null> = computed<Organisation | null>(() => {
    if (!user.value || !user.value.organisations) return null;
    const lastOrgId: string | null = user.value.userDetails.lastLoggedInKnowledgeBaseOrganisationId || null;
    if (!lastOrgId) return user.value.organisations.items[0];
    return user.value.organisations.items.find((org): boolean => org.id === lastOrgId) || null;
  });

  const userProducts: ComputedRef<Product[]> = computed<Product[]>(() => {
    const currentOrg: Organisation | null = selectedOrganisation.value;
    if (!currentOrg) return [];
    const domains = currentOrg._getEmbedded('domains').data;
    return domains.map(
      (domain: Domain) => user.value?.products.items.find((prod): boolean => prod.code === domain.productCode) || null
    );
  });

  const userDomains: ComputedRef<Domain[]> = computed<Domain[]>(() => {
    const currentOrg: Organisation | null = selectedOrganisation.value;
    if (!currentOrg) return [];
    return currentOrg._getEmbedded('domains').data;
  });

  const isDomainPermitted = (domain: DomainCode | null): boolean =>
    userDomains.value.some((userDomain: Domain) => userDomain.productCode === domain);

  const lastChosenDomain: ComputedRef<Domain | null> = computed<Domain | null>(() => {
    if (!user.value) return null;
    const loginProduct: string | null = user.value.userDetails.lastLoggedInKnowledgeBaseProductCode;
    const availableDomains: Domain[] = userDomains.value;
    let domain = null;
    if (productCode.value) {
      domain = availableDomains.find((dom: Domain): boolean => dom?.productCode === productCode.value);
    }
    if (!domain) {
      domain = availableDomains.find((dom: Domain): boolean => dom?.productCode === loginProduct);
    }
    return domain || null;
  });

  const lastChosenContextOrganisationId: ComputedRef<string | null> = computed<string | null>(() => {
    if (excludedFromContextOrganisation()) return null;

    if (!storedContextOrganisationId.value) {
      const selectedOrgId = selectedOrganisation.value?.id;
      if (!selectedOrgId) return null;

      // When selected one of the main organisations in the overrides, the context organisation is not set
      if (selectedOrgId in contextOrganisationOverrides) return '-';

      return selectedOrgId;
    }

    return storedContextOrganisationId.value;
  });

  const fetchUserData = async (): Promise<UserDataInterface> => {
    try {
      const response = await kbApi.get('assistant/user/get-attributes');
      return response.data;
    } catch (error: unknown) {
      if ((error as AxiosError).response?.status === 403 || (error as AxiosError).response?.status === 401) {
        await router.push({ name: 'unauthorized' });
      } else {
        await router.push({ name: 'error' });
      }
      throw error;
    }
  };

  const sendLoginNotification = async () => {
    await kbApi.post('assistant/user/login-notify', {
      organisationId: user.value?.userDetails.lastLoggedInKnowledgeBaseOrganisationId
    });
  };

  const init = async (): Promise<void> => {
    if (!isAuthenticated.value) return;
    user.value = new User(await fetchUserData());
    await sendLoginNotification();
    permissions.value = selectedOrganisation.value?.permissions || null;
  };

  const setLastChosenDomainCode = (code: string): void => {
    productCode.value = code;
    localStorage.setItem('productCode', code);
  };

  const setLastChosenContextOrganisationId = (id: string | null): void => {
    storedContextOrganisationId.value = id;
  };

  const excludedFromContextOrganisation = (): boolean => {
    const ids: string[] = [];
    const selectedOrgId = selectedOrganisation.value?.id;
    return selectedOrgId ? ids.includes(selectedOrgId) : false;
  };

  const getContextOrganisationIdentifier = (id: string): string | null => {
    if (id === '-') return null;
    return availableContextOrganisations.value.find((organisation) => organisation.value === id)?.label || id;
  };

  const availableContextOrganisations = computed(() => {
    const selectedOrgId = selectedOrganisation.value?.id;
    if (!selectedOrgId) return [];

    if (selectedOrgId in contextOrganisationOverrides) {
      return contextOrganisationOverrides[selectedOrgId].map((org: { id: string; name: string }) => ({
        value: org.id,
        label: org.name
      }));
    }

    return [
      {
        value: selectedOrgId,
        label: selectedOrganisation.value?.name || ''
      }
    ];
  });

  const resetStore = (): void => {
    user.value = null;
    productCode.value = localStorage.getItem('productCode');
    permissions.value = null;
    storedContextOrganisationId.value = null;
  };

  return {
    user,
    productCode,
    permissions,
    storedContextOrganisationId,
    isAuthenticated,
    isFirstLogin,
    userDetails,
    userOrganisations,
    selectedOrganisation,
    userProducts,
    userDomains,
    lastChosenDomain,
    lastChosenContextOrganisationId,
    init,
    setLastChosenDomainCode,
    setLastChosenContextOrganisationId,
    excludedFromContextOrganisation,
    isDomainPermitted,
    getContextOrganisationIdentifier,
    availableContextOrganisations,
    fetchUserData,
    sendLoginNotification,
    resetStore
  };
});
