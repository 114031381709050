<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import { TagVariants } from '@schulinck/components/ui/tag/Tag.types';
import Tag from '@schulinck/components/ui/tag/Tag.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { ComponentPublicInstance, onUnmounted, ref, watch } from 'vue';

import { Source } from '@/models/Message';

import SourceListItem from './SourceListItem.vue';

interface Props {
  title: string;
  subtitle: string;
  sources: Source[];
  selectedSource: number;
}

defineProps<Props>();

const headerElement = ref<ComponentPublicInstance>();

const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1), {
  threshold: [1]
});

onUnmounted(() => {
  if (headerElement.value) observer.unobserve(headerElement.value?.$el);
});

watch(headerElement, (header) => {
  if (header) observer.observe(header?.$el);
});
</script>

<template>
  <Stack :gap="0">
    <Stack ref="headerElement" class="source-pane-header" :gap="0">
      <Cluster :alignItems="Position.Center" :gap="0.5">
        <Typography :variant="TypographyVariants.H4" noMargin>{{ title }}</Typography>
        <Tag :variant="TagVariants.BlueTint">{{ sources.length }} </Tag>
      </Cluster>
      <Typography :variant="TypographyVariants.H5" noMargin>{{ subtitle }}</Typography>
    </Stack>
    <Stack :gap="0.75">
      <SourceListItem
        v-for="(source, index) in sources"
        :key="index"
        :source="source"
        :focussed="selectedSource === index"
      />
    </Stack>
  </Stack>
</template>

<style scoped lang="scss">
.source-pane-header {
  padding: 2rem 1rem 1rem 1rem;
  position: sticky;
  top: -1px; /* needed for intersection observer */
  background-color: $wk-white;
  z-index: 1;
  transition: box-shadow 0.3s ease;
  border-bottom: 4px solid transparent;

  &.is-pinned {
    box-shadow: 0 4px 8px 0 rgba($wk-black, 0.1);
  }

  h4 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }

  h5 {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 2;
    color: inherit;
  }

  ::v-deep(.wk-tag-blue-tint) {
    background-color: $wk-blue-tint5;
  }
}
</style>
