import { DomainCode } from './Domain';
import { MessageInterface } from './Message';

export enum ErrorReturnType {
  Message = 'message',
  Notification = 'notification'
}

export interface ErrorMessage {
  thread_uuid: string | null;
  trace_id: string | null;
  message: MessageInterface;
  messages: MessageInterface[];
  domain: DomainCode | null;
  contextOrganisationId: string | null;
  readOnly: boolean | null;
}
