import { DomainCode } from '@/models/Domain';
import { CustomAxiosRequestConfig } from '@/services/auth/authService';
import { InvokeInput, MessagesInput } from '@/stores/thread';

import { MockAIMessage, MockHumanMessage, mockThreads, mockThreadUuids } from './mockResponses';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const mockApi = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  post: async (url: string, { input }: { input?: MessagesInput | InvokeInput } = {}) => {
    switch (url) {
      case '/chat/invoke':
        await delay(2000);
        return {
          data: {
            output: {
              thread_uuid: '46c884da-c5d1-4146-98af-f6d1fa0e1942',
              message: MockAIMessage
            }
          }
        };
      case '/chat/messages':
        if (!input) {
          return {
            detail: [
              {
                type: 'missing',
                loc: ['thread_uuid'],
                msg: 'Field required',
                input: {},
                url: 'https://errors.pydantic.dev/2.10/v/missing'
              }
            ]
          };
        } else if (input.thread_uuid && !mockThreadUuids.includes(input.thread_uuid)) {
          return {
            detail: 'No message history found'
          };
        } else {
          return {
            data: {
              output: {
                messages: [MockHumanMessage, MockAIMessage],
                domain: DomainCode.Participatiewet,
                read_only: false
              }
            }
          };
        }
      case '/chat/threads':
        return {
          data: {
            output: {
              threads: mockThreads
            }
          }
        };
      case '/chat/editorial-feedback':
        return {
          data: {
            output: {
              feedback: 'This is a mock feedback message.'
            }
          }
        };
      default:
        throw new Error('Unknown API endpoint');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  get: async (url: string, config?: CustomAxiosRequestConfig) => {
    switch (url) {
      default:
        throw new Error('Unknown API endpoint');
    }
  }
};

export default mockApi;
