import { event as gtagEvent } from 'vue-gtag';

import { Event } from '@/services/tracking/event';

class Tracker {
  public push(event: Event<unknown>): void {
    gtagEvent(event.getName(), event.getData());
    if (!import.meta.env.PROD) {
      console.info('Tracked event:', {
        type: event.getName(),
        data: event.getData()
      });
    }
  }
}

export const tracker: Tracker = new Tracker();
