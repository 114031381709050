<script setup lang="ts">
import Clickable from '@schulinck/components/ui/clickable/Clickable.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import { ref } from 'vue';

import AnimatedDeleteIcon from '@/components/layout/sidebar/ThreadsList/Icons/AnimatedDeleteIcon.vue';
import { useLocaleTranslator } from '@/translations';

interface Props {
  values: string[];
  onAddGasIds: (ids: string[]) => void;
}

const props = defineProps<Props>();

const { translate } = useLocaleTranslator();

const internalValues = ref<string[]>(props.values.length > 0 ? props.values : ['']);

const updateValue = (index: number, value: string) => {
  internalValues.value[index] = value;
  props.onAddGasIds(internalValues.value);
};

const addInput = () => {
  internalValues.value.push('');
  props.onAddGasIds(internalValues.value);
};

const removeInput = (index: number) => {
  internalValues.value.splice(index, 1);
  props.onAddGasIds(internalValues.value);
};
</script>

<template>
  <div class="gas-id-container">
    <div v-for="(item, index) in internalValues" :key="index" class="input-group">
      <input
        type="text"
        :value="item"
        :placeholder="translate('editorialFeedback.gasIdPlaceholder')"
        @input="(e) => updateValue(index, (e.target as HTMLInputElement)?.value)"
      />
      <Clickable
        v-if="internalValues.length > 1"
        class="delete-btn thread-list-item-menu-button"
        @click.stop.prevent="removeInput(index)"
      >
        <AnimatedDeleteIcon />
      </Clickable>
    </div>

    <Clickable @click.stop.prevent="addInput">
      <Icon :name="IconNames.Plus" />
    </Clickable>
  </div>
</template>

<style scoped lang="scss">
.gas-id-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1%;
  margin-bottom: 12px;
}

.input-group {
  width: 32%;
  display: flex;
  margin-bottom: 0.5rem;
}

input {
  flex: 1;
  padding: 8px;
  border: 1px solid $wk-gray-tint4;
  border-radius: 4px 0 0 4px;
  border-right: none;

  &:last-child {
    border-radius: 4px 4px 4px 4px;
    border-right: 1px solid $wk-gray-tint4;
  }

  &:focus {
    outline: none;
  }
}

.add-btn {
  font-size: 48px;
}

.delete-btn {
  border-radius: 0 4px 4px 0;
  border: 1px solid $wk-gray-tint4;
  border-left: none;
  width: 2rem;
  padding-top: 0.375rem;
}
</style>
