<script setup lang="ts">
import { marked } from 'marked';
import { computed } from 'vue';

interface Props {
  content: string | null;
  type?: string | null;
}

const props = defineProps<Props>();

const content = computed(() => marked(props.content ?? '', { breaks: true }));
</script>

<template>
  <div :class="type" v-html="content" />
</template>

<style lang="scss" scoped>
.source {
  ::v-deep(h1),
  ::v-deep(h2),
  ::v-deep(h3),
  ::v-deep(h4),
  ::v-deep(h5),
  ::v-deep(h6) {
    font-size: 1rem;
    margin-top: 0.1rem;
    margin-bottom: 0.2rem;
  }

  ::v-deep(p),
  ::v-deep(ul),
  ::v-deep(li),
  ::v-deep(ol),
  ::v-deep(table) {
    font-size: 0.875rem;
  }

  ::v-deep(p) {
    margin-bottom: 0.5rem;
  }

  ::v-deep(thead th) {
    font-weight: normal;
  }
}

@import '@/assets/scss/shared-typography';
</style>
