import { CustomAxiosRequestConfig } from '@/services/auth/authService';
import { InvokeInput, MessagesInput } from '@/stores/thread';

import { mockUserData } from './mockResponses';

const mockApi = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  post: async (url: string, { input }: { input?: MessagesInput | InvokeInput } = {}) => {
    switch (url) {
      case 'assistant/user/login-notify':
        return { data: { success: true } };
      case 'auth/refresh-access-token':
        return {
          data: {
            JWT: {
              access: 'mock-access-token',
              refresh: 'mock-refresh-token'
            }
          }
        };
      default:
        throw new Error('Unknown API endpoint');
    }
  },
  get: async (url: string, config?: CustomAxiosRequestConfig, shouldFail?: boolean) => {
    switch (url) {
      case 'assistant/user/get-attributes':
        if (shouldFail) {
          return {
            response: {
              status: 403,
              data: {
                message: 'Forbidden'
              }
            }
          };
        }
        return {
          data: mockUserData
        };
      case 'assistant/auth/refresh-access-token':
        if ((config?.headers ?? {})['X-Authorization-Refresh-Token'] === 'valid-refresh-token') {
          return {
            data: {
              JWT: {
                access: 'new-mock-access-token',
                refresh: 'new-mock-refresh-token'
              }
            }
          };
        } else {
          throw new Error('Invalid refresh token');
        }
      case 'user/logout-user/b90e010b-bc36-4666-9a32-9b2dfecba16f':
        return {
          data: {
            status: 'OK',
            message: 'User was logged out successfully.'
          }
        };
      default:
        throw new Error('Unknown API endpoint');
    }
  }
};

export default mockApi;
