import { QuestionType } from '@/models/Question';

export enum EventType {
  ApplicationInitialized = 'application_initialized',
  UserLoggedIn = 'user_logged_in',
  UserLoggedOut = 'user_logged_out',
  DomainSelected = 'domain_selected',
  DisclaimerAccepted = 'disclaimer_accepted',
  SourceClicked = 'source_clicked',
  SourceSidebar = 'source_sidebar',
  SourceVisited = 'source_visited',
  NewThread = 'new_thread',
  PromptLengthExceeded = 'prompt_length_exceeded',
  ProfileMenu = 'profile_menu',
  ProfileMenuItem = 'profile_menu_item',
  AnswerFeedback = 'answer_feedback',
  AnswerFeedbackForm = 'answer_feedback_form',
  SourceFeedback = 'source_feedback',
  QuestionAsked = 'question_asked',
  DurationCustomerRequest = 'duration_customer_request',
  ApiError = 'api_error',
  HistorySidebar = 'history_sidebar',
  HistoryThreadOpened = 'history_thread_opened'
}

export enum EventState {
  Open = 'open',
  Closed = 'closed'
}

export interface EventData {
  [key: string]: unknown;
}

export interface DefaultEventData extends EventData {
  domain_code?: string;
  unix_timestamp: number;
  user_uuid?: string;
  user_organisatie?: string;
  user_vakgebied?: string;
  user_product: string;
  user_rol?: string;
  user_functie?: string;
}

export interface EventNoData {}
export interface DomainSelectedEventData {
  domain_code: string;
}
export interface SourceClickedEventData {
  thread_uuid: string;
  title: string;
  content_type: string;
  content_id: number;
}
export interface SourceSidebarEventData {
  state: EventState;
}
export interface SourceVisitedEventData {
  thread_uuid: string;
  title: string;
  content_type: string;
  content_id: number;
  permalink: string;
}
export interface ProfileMenuEventData {
  state: EventState;
}
export interface ProfileMenuItemEventData {
  menu_item: string;
}
export interface ResponseFeedbackEventData {
  trace_id: string;
  value: string;
}

export interface SourceFeedbackEventData {
  trace_id: string;
  value: string;
  content_type: string;
  content_id: number;
}

export interface FeedbackFormEventData {
  trace_id: string;
  reason?: string;
}
export interface QuestionAskedEventData {
  thread_uuid: string | null;
  type: QuestionType;
  count: number;
}
export interface DurationCustomerRequestEventData {
  thread_uuid: string | null;
  duration_ms: number;
}
export interface ApiErrorEventData {
  error: number | string;
}
export interface HistorySidebarEventData {
  state: EventState;
}
export interface HistoryThreadOpenedEventData {
  thread_uuid: string;
}
