import { addPrefixToKeys, getPrefixFromFileName } from '@/services/utils/translation';

import about from './about';
import action from './action';
import contentType from './contentType';
import conversation from './conversation';
import cookie from './cookie';
import date from './date';
import editorialFeedback from './editorialFeedback';
import error from './error';
import feedback from './feedback';
import footer from './footer';
import general from './general';
import loginPopup from './loginPopup';
import municipalPolicy from './municipalPolicy';
import pageTitle from './pageTitle';
import profileMenu from './profileMenu';
import prompt from './prompt';
import sidebar from './sidebar';
import sources from './sources';

const files = {
  about,
  action,
  contentType,
  conversation,
  cookie,
  date,
  editorialFeedback,
  error,
  feedback,
  footer,
  loginPopup,
  municipalPolicy,
  pageTitle,
  profileMenu,
  prompt,
  sidebar,
  sources
};

const translationsWithPrefix: NonNullable<unknown> = Object.entries(files).reduce(
  (acc: NonNullable<unknown>, [fileName, fileContent]) => {
    const prefix: string = getPrefixFromFileName(fileName);
    const prefixedTranslations: Record<string, unknown> = addPrefixToKeys(
      fileContent as Record<string, unknown>,
      prefix
    );
    return { ...acc, ...prefixedTranslations };
  },
  {}
);

export default {
  ...translationsWithPrefix,
  ...general
};
