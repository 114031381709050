import axios, { InternalAxiosRequestConfig } from 'axios';

import mockApi from '@/services/api/assistant/mockApi';
import AuthService from '@/services/auth/authService';
import { isTokenExpired } from '@/services/auth/jwt/validator';
import { stringToBool } from '@/services/utils/typeCasters';
import { useUserStore } from '@/stores/user';

const api = axios.create({
  baseURL: import.meta.env.VITE_SA_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    let token = AuthService.getAccessToken();

    if (token && isTokenExpired(token)) {
      token = await AuthService.refreshAccessToken();
    }

    const permissionsToken = useUserStore().selectedOrganisation?.permissionsToken;
    const organisationId = useUserStore().selectedOrganisation?.id;

    if (permissionsToken && organisationId) {
      Object.assign(config.headers, {
        ...config.headers,
        'X-Permissions-Token': permissionsToken,
        'X-Organisation-Id': organisationId
      });
    }

    if (token) {
      Object.assign(config.headers, {
        ...config.headers,
        'X-Authorization-Token': token
      });
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await AuthService.refreshAccessToken(originalRequest.url);
        originalRequest.headers = {
          ...originalRequest.headers,
          'X-Authorization-Token': newAccessToken
        };

        return api(originalRequest);
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);

        return Promise.reject(error);
      }
    }

    console.error('Error (Schulinck Assistant API):', error);

    return Promise.reject(error);
  }
);

export default stringToBool(import.meta.env.VITE_USE_MOCK_API) ? mockApi : api;
