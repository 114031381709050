import { App } from 'vue';
import VueGtag from 'vue-gtag';

import { useLocaleTranslator } from '@/translations';

const { translate } = useLocaleTranslator();

export const initGtag = (app: App): void => {
  app.use(VueGtag, {
    appName: translate('application.title'),
    config: {
      id: import.meta.env.VITE_GA_ID,
      params: {
        anonymize_ip: true,
        cookie_domain: 'none'
      }
    }
  });
};
