<script setup lang="ts">
import Clickable from '@schulinck/components/ui/clickable/Clickable.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import { ref } from 'vue';

import { Thread } from '@/models/Threads';

import ThreadsListItemMenu from './ThreadsListItemMenu.vue';

interface Props {
  active: boolean;
  thread: Thread;
  onThreadClicked: (thread: Thread) => void;
}
const props = defineProps<Props>();

const showMenu = ref(false);
const listItemRef = ref();
const toggleMenu = () => (showMenu.value = !showMenu.value);
const hideMenu = () => (showMenu.value = false);

const threadClicked = (thread: Thread, e: MouseEvent) => {
  e.preventDefault();

  const keyEvent = window.event as KeyboardEvent;
  if (keyEvent.ctrlKey || keyEvent.metaKey) window.open(`/chat/${thread.thread_uuid}`, '_blank');
  else props.onThreadClicked(thread);
};

document.addEventListener('click', (event: MouseEvent) => {
  if (listItemRef.value && event.target !== listItemRef.value && !listItemRef.value.contains(event.target)) hideMenu();
});
</script>

<template>
  <p ref="listItemRef" :class="['threads-list-item', active && 'active']">
    <Clickable class="thread-link" :href="`/chat/${thread.thread_uuid}`" @click.left="threadClicked(thread, $event)">
      {{ thread.title }}
    </Clickable>
    <button class="thread-menu-button" @click="toggleMenu">
      <Icon :name="IconNames.More" filled />
    </button>
    <ThreadsListItemMenu v-if="showMenu" :thread="thread" :hideMenu="hideMenu" />
  </p>
</template>

<style scoped lang="scss">
.threads-list-item {
  display: inline-flex;
  gap: 0.5rem;
  color: $wk-gray-shade1;
  padding: 0.375rem 0.75rem 0.25rem 0.75rem;
  font-size: 0.875rem;
  transition: all 300ms ease-in-out;
  opacity: 1;
  width: 100%;

  .wk-clickable {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    transition: width 0.5s ease-in-out;
  }

  .thread-menu-button .wk-icon {
    color: $wk-blue-shade1;
    transform: rotate(0.25turn);
    transform-origin: center center;
  }

  .thread-menu-button {
    opacity: 0;
    width: 1rem;
  }

  &:hover {
    .thread-menu-button {
      opacity: 1;
    }
  }

  &:hover,
  &.active {
    color: $wk-blue-shade1;
    background-color: $wk-blue-tint5;
  }

  &.active {
    font-weight: 500;

    .thread-link {
      cursor: default;
    }
  }
}
</style>
