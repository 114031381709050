<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import { Colors } from '@schulinck/components/theme/color';
import { Position } from '@schulinck/components/theme/position';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { computed } from 'vue';

import { formatDate } from '@/services/utils/date';
import { useLocaleTranslator } from '@/translations';

const { translate } = useLocaleTranslator();

interface Props {
  loading: boolean;
  domain: string;
  contextOrganisationName: string | null;
  createdAt: number | null;
}

const { loading = false, domain = '', contextOrganisationName = null, createdAt = null } = defineProps<Props>();

const formattedCreatedAt = computed(() => {
  if (createdAt === null) return 'Unknown Date';
  return formatDate(new Date(createdAt * 1000));
});
</script>

<template>
  <Typography
    v-if="loading"
    :variant="TypographyVariants.Paragraph"
    :color="Colors.Gray"
    class="conversation-meta loading"
  >
    {{ translate('conversation.meta.loading') }}
  </Typography>
  <Cluster v-else :alignItems="Position.Center" :gap="0.5" class="conversation-meta">
    <Cluster :alignItems="Position.Center" :gap="0.25">
      <Icon class="icon" :name="IconNames.Book" :color="Colors.Gray" />
      <Typography :variant="TypographyVariants.Paragraph" :color="Colors.Gray">
        {{ domain }}
      </Typography>
    </Cluster>
    <Cluster>
      <Typography :variant="TypographyVariants.Paragraph" :color="Colors.Gray"> &middot; </Typography>
    </Cluster>
    <Cluster v-if="contextOrganisationName !== null" :alignItems="Position.Center" :gap="0.25">
      <Icon class="icon" :name="IconNames.Municipality" :color="Colors.Gray" />
      <Typography :variant="TypographyVariants.Paragraph" :color="Colors.Gray">
        {{ contextOrganisationName }}
      </Typography>
    </Cluster>
    <Cluster v-if="contextOrganisationName !== null">
      <Typography :variant="TypographyVariants.Paragraph" :color="Colors.Gray"> &middot; </Typography>
    </Cluster>
    <Cluster :alignItems="Position.Center" :gap="0.5">
      <Typography :variant="TypographyVariants.Paragraph" :color="Colors.Gray">
        {{ formattedCreatedAt }}
      </Typography>
    </Cluster>
  </Cluster>
</template>

<style scoped lang="scss">
.icon {
  margin: 0;
}

.conversation-meta {
  font-size: 14px;

  &.loading {
    animation: pulse 2s ease-in-out infinite;
  }
}

@keyframes pulse {
  50% {
    opacity: 0.2;
  }
}
</style>
