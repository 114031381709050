<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import Clickable from '@schulinck/components/ui/clickable/Clickable.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import { TagVariants } from '@schulinck/components/ui/tag/Tag.types';
import Tag from '@schulinck/components/ui/tag/Tag.vue';
import { TooltipPosition } from '@schulinck/components/ui/tooltip/Tooltip.types';
import Tooltip from '@schulinck/components/ui/tooltip/Tooltip.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { type ComponentPublicInstance, computed, onMounted, ref, watch } from 'vue';

import MarkdownContent from '@/components/conversation/MarkdownContent.vue';
import SourceBreadcrumbs from '@/components/conversation/response/source/SourceBreadcrumbs.vue';
import { EventType, SourceVisitedEventData } from '@/models/Event';
import { ContentType, Source } from '@/models/Message';
import { Permission } from '@/models/PermissionSet';
import { Event } from '@/services/tracking/event';
import { tracker } from '@/services/tracking/tracker';
import { useThreadStore } from '@/stores/thread';
import { useUserStore } from '@/stores/user';
import { useLocaleTranslator } from '@/translations';

import SourceFeedback from '../feedback/SourceFeedback.vue';

const thread = useThreadStore();
const userStore = useUserStore();

interface Props {
  source: Source;
  focussed?: boolean;
}

const props = defineProps<Props>();

const { translate } = useLocaleTranslator();

const element = ref<ComponentPublicInstance>();
const open = ref(props.focussed);
const sourceVisitedEvent: Event<SourceVisitedEventData> = new Event().setType(EventType.SourceVisited);
const tooltipTitle = computed(() => (open.value ? translate('sources.readLess') : translate('sources.readMore')));

const focussedItem = ref<number | null>(props.focussed ? props.source.content_id : null);

const toggleChunk = () => {
  const isFocussed = focussedItem.value === props.source.content_id;
  focussedItem.value = isFocussed ? null : props.source.content_id;
  open.value = !isFocussed;
};

const sourceClick = () => {
  sourceVisitedEvent.setData({
    thread_uuid: thread.uuid,
    title: props.source.title,
    content_type: props.source.content_type,
    content_id: props.source.content_id,
    permalink: props.source.permalink
  });

  tracker.push(sourceVisitedEvent);
};

const contextOrganisationName = computed(() =>
  userStore.getContextOrganisationIdentifier(thread.contextOrganisationId)
);

watch(focussedItem, (newVal) => {
  open.value = newVal === props.source.content_id;
});

onMounted(() => {
  if (props.focussed && element.value) {
    setTimeout(() => {
      const el = element.value?.$el;
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 300);
  }
});
</script>
<template>
  <div>
    <Stack
      ref="element"
      :justifyContent="Position.Between"
      :class="['source-list-item', open && 'focussed']"
      :gap="0"
      @click.prevent="toggleChunk"
    >
      <div class="header">
        <Cluster class="tags" :alignItems="Position.Center" :gap="0.25" :wrap="true">
          <Tag
            v-if="source.content_type == ContentType.Guideline && contextOrganisationName"
            :variant="TagVariants.BlueTint"
            >{{ contextOrganisationName }}
          </Tag>
        </Cluster>
        <Cluster :alignItems="Position.Center" :justifyContent="Position.Between">
          <Typography class="title" :variant="TypographyVariants.H5" noMargin>{{ source.title }}</Typography>
          <Cluster :alignItems="Position.Center" :gap="0">
            <SourceFeedback v-if="$hasPermission(Permission.SourceFeedback)" :source />
            <Clickable class="chevron">
              <Tooltip :title="tooltipTitle" :position="TooltipPosition.Left">
                <Icon :name="IconNames.ChevronDown" :rotate="open" />
              </Tooltip>
            </Clickable>
          </Cluster>
        </Cluster>
        <SourceBreadcrumbs
          class="breadcrumbs"
          :breadcrumbs="source.breadcrumbs"
          :contentType="source.content_type"
          @click.stop
        />
      </div>
      <div :class="['chunk-container', open && 'open']">
        <div class="chunk" @click.stop>
          <MarkdownContent :type="'source'" :content="source.content" />
          <Clickable target="_blank" :href="source.permalink" class="source-link" :onClick="sourceClick"
            >{{ translate('sources.goToFullSource') }}<Icon :name="IconNames.ArrowRight" />
          </Clickable>
        </div>
      </div>
    </Stack>
  </div>
</template>

<style scoped lang="scss">
.source-list-item {
  cursor: pointer;
  margin: 0 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  background-color: rgba($wk-blue-tint6, 0.4);
  border-radius: 0.5rem;
  border: solid 1px rgba($wk-blue-shade1, 0.2);
  transition: all 0.2s;
  height: 100%;
  scroll-margin-top: 7rem;
  width: inherit;

  &.focussed,
  &:hover {
    border: solid 1px $wk-blue-shade1;
    background-color: $wk-blue-tint6;
  }

  .header {
    .title {
      color: $wk-gray-shade1;
      position: relative;
      font-weight: 500;
      width: 100%;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .tags {
      ::v-deep(.wk-tag) {
        background-color: transparent;
        border: 1px solid $wk-blue-shade1;
        padding: 0.1rem 0.5rem;
        color: $wk-blue-shade1;
        font-size: 0.6rem;
        margin-bottom: -0.5rem;
      }
    }

    .breadcrumbs {
      cursor: default;
      width: fit-content;
    }

    .chevron {
      padding: 0.25rem 0.375rem;
      border-radius: 50%;

      &:hover {
        background-color: $wk-blue-tint6;
      }
    }
  }

  .chunk-container {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 500ms;

    &.open {
      grid-template-rows: 1fr;
    }
  }

  .chunk {
    cursor: default;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .source {
      padding: 0.5rem 0 1rem 0;
      overflow: auto;
    }

    .source-link {
      color: $wk-blue-shade1;
      text-align: left;
      width: fit-content;
      align-self: start;
      font-size: 0.875rem;

      > i {
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        text-decoration: none;

        > i {
          padding-left: 0.25rem;
        }
      }
    }
  }
}
</style>
