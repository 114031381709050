<script setup lang="ts">
import Clickable from '@schulinck/components/ui/clickable/Clickable.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import { computed } from 'vue';

import { ThumbButtonsState } from '@/models/Feedback';

interface Props {
  clickedButton: ThumbButtonsState;
  onButtonClicked: (state: ThumbButtonsState) => void;
}

const props = defineProps<Props>();
const thumbsUpActive = computed(() => props.clickedButton === ThumbButtonsState.Up);

const thumbsUpButtonClicked = () => {
  props.onButtonClicked(thumbsUpActive.value ? ThumbButtonsState.None : ThumbButtonsState.Up);
};
</script>

<template>
  <div :class="['thumbsup', thumbsUpActive && 'active']">
    <Clickable class="up" @click.stop.prevent="thumbsUpButtonClicked">
      <Icon :filled="thumbsUpActive" :class="[thumbsUpActive && 'active']" :name="IconNames.ThumbsUp" />
    </Clickable>
  </div>
</template>

<style scoped lang="scss">
.thumbsup {
  .wk-clickable {
    position: relative;
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    border-radius: 50%;

    &:hover {
      background-color: $wk-blue-tint6;
    }

    i {
      color: $wk-blue-shade1;
      margin: 0;

      &.active {
        color: $wk-green-shade1;
        animation: bounceUp 0.2s ease-in-out forwards;
      }
    }
  }
}

@keyframes bounceUp {
  0% {
    transform: translateY(0) rotate(0);
  }
  33% {
    transform: translateY(-8px);
  }
  50% {
    transform: translateY(-8px) rotate(-20deg);
  }
  75% {
    transform: translateY(-4px) rotate(-15deg);
  }
}
</style>
