export interface ConsentValues {
  Necessary: boolean;
  Functional: boolean;
  Performance: boolean;
  Interest: boolean;
}

export interface UserDataTrust {
  consent: ConsentValues;
  runAfterNewConsent: () => void;
}

export const loadCookieConsentScript = (
  scriptUrl: string,
  callback: (consentValues: ConsentValues) => void
): Promise<ConsentValues> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.async = true;
    script.onload = () => {
      const userDataTrust = window.UserDataTrust;
      if (userDataTrust?.consent) {
        const consent = JSON.parse(JSON.stringify(userDataTrust.consent));
        callback(consent);
        userDataTrust.runAfterNewConsent = () => {
          const updatedConsent = userDataTrust.consent ?? {};
          callback(updatedConsent);
          if (JSON.stringify(consent) !== JSON.stringify(updatedConsent)) {
            window.location.reload();
          }
        };
        resolve(consent);
      } else {
        reject(new Error('UserDataTrust or consent is undefined.'));
      }
    };
    script.onerror = () => reject(new Error('Failed to load cookie consent script.'));
    document.head.appendChild(script);
  });
};

export const getConsentValues = async (): Promise<ConsentValues> => {
  return await loadCookieConsentScript(import.meta.env.VITE_COOKIE_CONSENT_SCRIPT_URL, (consentValues): void => {
    if (!import.meta.env.PROD) {
      console.info('Cookie consent values loaded:', consentValues);
    }
  });
};
