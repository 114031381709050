import Resource from '@schulinck/lib/hateoas/resources/Resource';

import Domain from './Domain';
import PermissionSet from './PermissionSet';

export interface OrganisationDataInterface {
  id: string;
  name: string;
  role: string;
  permissionsToken: string;
  domains: Domain[];
  permissions: PermissionSet | null;
}

export interface ContextOrganisation {
  id: string;
  name: string;
}

export interface OrganisationResponseInterface {
  total: number;
  organisations: OrganisationDataInterface[];
}

export default class Organisation extends Resource {
  public readonly id: string;
  public readonly name: string;
  public readonly role: string;
  public readonly permissionsToken: string;
  public readonly domains: Domain[];
  public readonly permissions: PermissionSet | null;

  constructor(data: OrganisationDataInterface) {
    super(data);

    this.id = data.id;
    this.name = data.name;
    this.role = data.role;
    this.permissionsToken = data.permissionsToken;
    this.domains = this._getEmbeddedObject('domains', Domain);
    this.permissions = this._getEmbeddedObject('permissions', PermissionSet);
  }
}
